import React, { useState } from 'react';
import {Link} from 'react-router-dom';

import PageBanner from './../layouts/PageBanner';

//images
import bgImage from './../assets/images/banner/bnr1.jpg';
import pic1 from './../assets/images/main-slider/slider2/pic1.png';
import about from './../assets/images/about/pic1.jpg';
import signature from './../assets/images/signature.png';
import bg4 from './../assets/images/background/bg4.jpg';
import international from './../assets/images/international.png';

//import shape1 from './../assets/images/pattern/shape1.png';
//import shape2 from './../assets/images/pattern/shape2.png';
//import shape3 from './../assets/images/pattern/shape3.png';
//import shape5 from './../assets/images/pattern/shape5.png';
//import shape6 from './../assets/images/pattern/shape6.png';
import NewsSlider from '../components/Home/NewsSlider';
import UpdateBlog from '../components/Home/UpdateBlog';
import OurMission from '../components/OurMission';
import { IMAGES } from '../constant/theme';
import ModalVideo from 'react-modal-video';
import ServiceBlog from '../components/Home2/ServiceBlog';
import bg10 from '../assets/images/background/bg10.png';

import team1 from './../assets/images/team/team1.jpg';
import team2 from './../assets/images/team/team2.jpg';
import team3 from './../assets/images/team/team3.jpg';
import team4 from './../assets/images/team/team4.jpg';
import team5 from './../assets/images/team/team5.jpg';
import team6 from './../assets/images/team/team6.jpg';
import team7 from './../assets/images/team/team7.jpg';
import team8 from './../assets/images/team/team8.jpg';
import team9 from './../assets/images/team/team9.jpg';
import team10 from './../assets/images/team/team10.jpg';
import team11 from './../assets/images/team/team11.jpg';
import team12 from './../assets/images/team/team12.jpg';
import { rootImagePath } from '../data/content';

const iconBlog = [
    {title:"Mission", content: "To provide quality education, healthcare, and social services to children, youths, and the elderly in rural communities, in order to improve quality of life."},
    {title:"Vision", content: "Transforming lives by empowering generations through strategic and intentional intervention programs."}
];

const wrapperBlog = [
    {
        icon:"flaticon-vr-glasses",
        title:"Family & Community",
        text: "We believe in transforming our community, one family at a time, one individual at a time ."
    },
    {
        icon:"flaticon-transformation",
        title:"Commitment",
        text: "We are committed to social change and advocacy through Community Development, Health, Education, Empowerment, Food and Water (CHEEF)."
    },
    {
        icon:"flaticon-doctor-bag",
        title:"Teamwork",
        text: "Together as a team we make our dreams work"
    },
    {
        icon:"flaticon-open-book",
        title:"Trust",
        text: "We hold ourselves to the highest standards to ensure accountability, transparency and sustainable partnerships for our goals"
    },
    {
        icon:"flaticon-open-book",
        title:"Empathy",
        text: "We feel as they feel - so together we work to rewrite our stories"
    },
    {
        icon:"flaticon-open-book",
        title:"Excellence",
        text: "It's not just what we do, it's our culture"
    },
    {
        icon:"flaticon-open-book",
        title:"Selfless Service",
        text: "We do not count the cost to create the world we desire, we simply do it"
    },
];

const cardDataBlog = [
    {image:`${rootImagePath}/general/management/Uduak-Charles-UduakCharlesDiaries.jpg`, title:"Uduak Charles", subtitle:"Chairman",social:[{type: "facebook", link: ""}]},
    {image:`${rootImagePath}/general/management/Peace-Eshiet-UduakCharlesDiaries.jpeg`, title:"Peace Eshiet", subtitle:"Chairwoman"},
    {image:`${rootImagePath}/general/management/Wisdom-Harold-UduakCharlesDiaries.jpeg`, title:"Wisdom Harold", subtitle:"Vice Chairman"},
    {image:``, title:"Jennifer Pius", subtitle:"Secretary"},
    {image:``, title:"Brian Nyong", subtitle:"Regional Director"},
    {image:`${rootImagePath}/general/management/Emmanuel-Kings-UduakCharlesDiaries.jpeg`, title:"Emmanuel Kings", subtitle:"Director of Budget"},
    {image:`${rootImagePath}/general/management/Nnenna-Robert-UduakCharlesDiaries.jpeg`, title:"Nnenna Robert", subtitle:"Human Resource"},
    {image:``, title:"Nameti Akpan", subtitle:"Liaison Officer"},
    {image:``, title:"Halleluyah Eseyin", subtitle:"Media Director"},
    {image:``, title:"Emediong Ebong", subtitle:"Director of Welfare"},
];

const AboutUs = () => {
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            <div className="page-content bg-white">
                <PageBanner maintitle="Home" pagetitle="About Us" background={`${rootImagePath}/general/team.jpeg`} />
                <section className="content-inner section-wrapper5">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="section-head">
                                    <h5 className="sub-title">Who We Are</h5>
                                    <h2>9+ years of building and renewing communities</h2>
                                    {/* <p className="m-t20 word-wrap-pre">We've seen the impact our work has on families and communities. We've seen the hope in the eyes of a child who has access to education, the relief on the face of a parent who can finally afford to feed their family, and the strength of a community that comes together to support each other.

However, our pursuit of recognition is not driven by personal gain or ego, but rather by the profound belief that it can serve as a catalyst for amplified support. With your resources and support, we can rally the forces needed to touch the lives of an even broader spectrum of individuals, spreading our mission like ripples in a vast ocean, and creating more words in our long diary.
                                    </p> */}
                                </div>
                                {iconBlog.map((data, index)=>(
                                    <div className="icon-bx-wraper box-hover style-2 m-b30" key={index}>
                                        <div className="icon-lg"> 
                                            <Link to={"/services-details"} className="icon-cell">
                                                <i className="flaticon-shield"></i>
                                            </Link> 
                                        </div>
                                        <div className="icon-content">
                                        <h5 className="dz-tilte m-b5 text-capitalize">{data.title}</h5>
                                            <p>{data.content}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="col-lg-5 m-b30">
                                <div className="dz-media">
                                    <img src={`${rootImagePath}/general/about-us.png`} alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content-inner-2 overlay-primary-dark about-wrapper2 bg-img-fix" style={{backgroundImage:"url("+ bg4 +")",  backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
                    <div className="container">
                        <div className="about-bx2">
                            <div className="row g-0">
                                <div className="col-lg-4">
                                    <div className="dz-media">
                                        <img src={`${rootImagePath}/general/management/Chairman-Uduak-Charles.jpg`} alt="Chairman, Uduak Charles Diaries" />
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="dz-info">
                                        <h2 className="title m-b30 m-md-b20">A Word from the Chairman</h2>
                                        <p className="text word-wrap-pre">Our journey began with a simple yet powerful vision - to give children, youths, and the elderly a better life. Today, I am in awe of how far we have come, and I am inspired by the countless lives we have touched along the way.<br />

At Uduak Charles Diaries Foundation, our mission is not just a statement; it is a calling that resonates deep within our hearts. We believe in the inherent worth and potential of every individual, and we are committed to creating lasting positive change in their lives. We understand that by empowering children, youths, and the elderly, we are building stronger communities and shaping a brighter future for all.<br />

Through our programs and initiatives, we aim to provide Community development, Healthcare, Education, Empowerment, Food and water, and each day, we witness the transformative power of our work. We see the joy in a child's eyes as they receive an education, the resilience of a youth who discovers their true potential, and the gratitude of an elderly person who feels valued and cared for. These moments reaffirm our commitment to the cause and fuel our determination to do even more.<br />

But we cannot do it alone. We are immensely grateful to our dedicated team, volunteers, and partners who share our passion and stand by our side. It is through your unwavering support that we are able to make a real difference in the lives of those we serve. Together, we are a force for good, a collective of changemakers striving to create a world where everyone has access to the opportunities they deserve.<br />

As we move forward, we remain guided by our vision and fueled by the unwavering spirit of giving. Our journey is far from over, and there are countless more lives to touch, new entries to write in our diary, and smiles to restore. We invite you to join us on this remarkable journey of empowerment and transformation. Together, we can effect change that extends far beyond our Foundation's reach.
</p>
                                        <div className="signature col-6">
                                            <img src={signature} alt="image" />
                                            {/* <p className="text-dark m-t15 mb-0">Uduak Charles</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="clearfix">
                    <div className="container-fluid">
                        <div className="content-inner bg-gray" style={{backgroundImage:"url("+ bg10 +")" ,backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
                            <div className="container">
                                <div className="section-head text-center wow fadeInUp" data-wow-delay="0.2s">
                                    <h5 className="sub-title">Our Core Values</h5>
                                    <h2 className="title">Principles guiding our work</h2>
                                </div>
                                <div className="row justify-content-center">
                                    <ServiceBlog />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="content-inner-2">
                    <div className="container">
                        <div className="section-head text-center wow fadeInUp" data-wow-delay="0.2s">
                            {/* <h5 className="sub-title">Leadership</h5> */}
                            {/* <h2 className="title">Principles guiding our work</h2> */}
                        </div>
                        <div className="row">
                            {cardDataBlog.filter(item => item.image).map((item, ind)=>(
                                <div className="col-xl-3 col-lg-4 col-sm-6" key={ind}>
                                    <div className="dz-team style-1 m-b30">
                                        <div className="dz-media">
                                            <img src={item.image} alt={item.title} />
                                            {/* <ul className="team-social">
                                                <li><a href="https://www.facebook.com/UduakCharlesDiaries" target="_blank" rel="nofollow noreferrer noopener" className="fab fa-facebook-f"></a></li>
                                                <li><a href="https://www.instagram.com/uduakcharlesdiaries" target="_blank" rel="nofollow noreferrer noopener" className="fab fa-instagram"></a></li>
                                                <li><a href="https://twitter.com/UCDiaries" target="_blank" rel="nofollow noreferrer noopener" className="fab fa-twitter"></a></li>
                                            </ul> */}
                                        </div>
                                        <div className="dz-content">
                                            <h5 className="dz-name">{item.title}</h5>
                                            <span className="dz-position text-primary">{item.subtitle}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                {/* <section className="content-inner">
                    <div className="container">
                        <div className="row align-items-center content-bx style-2">
                            <div className="col-lg-4 m-b30 wow fadeInUp" data-wow-delay="0.2s">
                                <div className="dz-media video-bx4 rounded">
                                    <img src={international} alt="image"/>
                                    <Link to={"#"} className="popup-youtube vedio-btn" onClick={()=> setOpen(true)} ><i className="fa-solid fa-play" ></i></Link>
                                </div>
                            </div>
                            <div className="col-lg-8 m-b30 wow fadeInUp" data-wow-delay="0.4s">
                                <div className="inner-content">
                                    <div className="section-head">
                                        <h2 className="title">From our International friends</h2>
                                        <p className="max-w400 word-wrap-pre">"We've seen the impact our work has on families and communities. We've seen the hope in the eyes of a child who has access to education, the relief on the face of a parent who can finally afford to feed their family, and the strength of a community that comes together to support each other.<br />

However, our pursuit of recognition is not driven by personal gain or ego, but rather by the profound belief that it can serve as a catalyst for amplified support. With your resources and support, we can rally the forces needed to touch the lives of an even broader spectrum of individuals, spreading our mission like ripples in a vast ocean, and creating more words in our long diary."</p>
                                    </div>
                                    <Link to={"/projects"} className="btn btn-primary">Project Story</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                {/* <section className="content-inner"> 
                    <div className="container">
                        <div className="section-head text-center">
                            <h5 className="sub-title">What we believe</h5>
                            <h2 className="m-b20">Our Core Values</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis no</p>
                        </div>
                        <div className="row">
                            {wrapperBlog.map((item, ind)=>(
                                <div className="col-lg-6 col-md-12 col-sm-12" key={ind}>
                                    <div className="icon-bx-wraper box-hover style-2 m-b30">
                                        <div className="icon-lg"> 
                                            <Link to={"/services-details"} className="icon-cell">
                                                <i className={item.icon}></i>
                                            </Link> 
                                        </div>
                                        <div className="icon-content">
                                            <h5 className="dz-tilte m-b10 text-capitalize">{item.title}</h5>
                                            <p>{item.text}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section> */}

                <section className="content-inner-2 bg-light section-pattren1">
                    <div className="container">
                        <div className="row about-bx3 align-items-center">
                            <OurMission />
                        </div>
                    </div>
                    <img src={IMAGES.Shape1} className="shape-1 move-1" alt="shape" />
                    <img src={IMAGES.Shape2} className="shape-2 move-2" alt="shape" />
                    <img src={IMAGES.Shape3} className="shape-3 move-1" alt="shape" />
                    <img src={IMAGES.Shape5} className="shape-4 rotating" alt="shape"/>
                    <img src={IMAGES.Shape6} className="shape-5 rotating" alt="shape"/>
                    <img src={IMAGES.Shape6} className="shape-6 rotating" alt="shape"/>
                </section>
                {/* <section className="content-inner-2">
                    <div className="container">
                        <div className="section-head text-center">
                            <h2 className="title">Akcel News</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                        </div>
                        <NewsSlider />     
                    </div>
                </section> */}
                <div className="call-action style-1 content-inner-1">
                    <div className="container">
                        <UpdateBlog />
                    </div>
                </div>
            </div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="zQqVwQnvgwc" onClose={() => setOpen(false)} />
        </>
    );
};


export default AboutUs;
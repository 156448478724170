import React from 'react';
import {Link} from 'react-router-dom';

const FooterTop = () => {
    return (
        <>
            <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 m-b30 wow fadeInUp" data-wow-delay="0.2s">
                    <div className="content-bx style-1 bg-primary text-center">
                        <div className="inner-content">
                            <h2 className="title text-white">Newsletter</h2>
                            <p className="text-white">Sign up for our monthly newsletter to get the latest news, volunteer opportunities.</p>
                            <form className="dzSubscribe" action="script/mailchamp.php" method="post">
                                <div className="dzSubscribeMsg text-white"></div>
                                <input name="dzEmail" required="required" type="email" className="form-control transparent m-b15" placeholder="Enter your email address" />
                                <button name="submit" value="Submit" type="submit" className="btn btn-secondary btn-rounded btn-block">Subscribe Now</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 m-b30 wow fadeInUp" data-wow-delay="0.6s">
                    <div className="content-bx style-1 bg-dark text-center">
                        <div className="inner-content">
                            <h2 className="title text-white m-b15 display-4 font-weight-700">500+</h2>
                            <p className="m-b30 text-white">Our projects are powered by efforts of several willing volunteers </p>
                            <Link to={"/volunteer"} className="btn btn-primary">Become a Volunteer</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 m-b30 wow fadeInUp" data-wow-delay="0.4s">
                    <div className="content-bx style-1 bg-secondary text-center">
                        <div className="inner-content">
                            <div className="icon-lg m-b20">
                                <Link to={"/donate"} className="icon-cell">
                                    <svg width="81" height="66" viewBox="0 0 81 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M32.8415 39.1484C31.7105 39.1484 30.7937 38.2316 30.7937 37.1006V12.1683C30.7937 11.2084 30.0129 10.4277 29.053 10.4277C28.0932 10.4277 27.3124 11.2084 27.3124 12.1683V37.1009C27.3124 38.2319 26.3955 39.1487 25.2646 39.1487C24.1337 39.1487 23.2168 38.2319 23.2168 37.1009V12.1683C23.2168 8.95016 25.8349 6.33203 29.053 6.33203C32.2712 6.33203 34.8893 8.95016 34.8893 12.1683V37.1009C34.8893 38.2317 33.9724 39.1484 32.8415 39.1484Z" fill="#141818"/>
                                        <path d="M17.6877 39.1483C16.5567 39.1483 15.6399 38.2314 15.6399 37.1005V10.2738C15.6399 9.31391 14.8591 8.53313 13.8992 8.53313C12.9394 8.53313 12.1586 9.31391 12.1586 10.2738V15.5152C12.1586 16.6461 11.2417 17.563 10.1108 17.563C8.97986 17.563 8.06299 16.6461 8.06299 15.5152V10.2738C8.06299 7.05563 10.6811 4.4375 13.8992 4.4375C17.1174 4.4375 19.7355 7.05563 19.7355 10.2738V37.1008C19.7355 38.2316 18.8186 39.1483 17.6877 39.1483Z" fill="#141818"/>
                                        <path d="M25.2646 39.1484C24.1336 39.1484 23.2168 38.2315 23.2168 37.1006V6.48493C23.2168 5.52509 22.436 4.74431 21.4761 4.74431C20.5163 4.74431 19.7355 5.52509 19.7355 6.48493V37.1007C19.7355 38.2317 18.8186 39.1485 17.6877 39.1485C16.5568 39.1485 15.6399 38.2317 15.6399 37.1007V6.48493C15.6399 3.26681 18.258 0.648682 21.4761 0.648682C24.6943 0.648682 27.3124 3.26681 27.3124 6.48493V37.1007C27.3124 38.2317 26.3955 39.1484 25.2646 39.1484Z" fill="#141818"/>
                                        <path d="M11.4736 59.8366C10.7258 59.8366 10.0051 59.4253 9.64452 58.7124C8.8953 57.2307 7.79718 55.9208 6.46874 54.9246C2.72265 52.1146 0.486084 47.6411 0.486084 42.9583V26.5286C0.486084 23.3105 3.10421 20.6924 6.32233 20.6924C9.54046 20.6924 12.1586 23.3105 12.1586 26.5286V35.3958C12.1586 36.5268 11.2417 37.4436 10.1108 37.4436C8.97983 37.4436 8.06296 36.5268 8.06296 35.3958V26.5286C8.06296 25.5688 7.28218 24.788 6.32233 24.788C5.36249 24.788 4.58171 25.5688 4.58171 26.5286V42.9583C4.58171 46.3591 6.20577 49.6077 8.9264 51.6483C10.7555 53.0203 12.2678 54.8241 13.2995 56.8644C13.8098 57.8736 13.4055 59.1055 12.3961 59.6158C12.0998 59.7657 11.7844 59.8366 11.4736 59.8366Z" fill="#141818"/>
                                        <path d="M40.4184 39.1484C39.2874 39.1484 38.3706 38.2315 38.3706 37.1006V17.8514C38.3706 16.8915 37.5898 16.1108 36.63 16.1108C35.6701 16.1108 34.8893 16.8915 34.8893 17.8514V37.1008C34.8893 38.2317 33.9725 39.1486 32.8415 39.1486C31.7106 39.1486 30.7937 38.2317 30.7937 37.1008V17.8514C30.7937 14.6333 33.4118 12.0151 36.63 12.0151C39.8481 12.0151 42.4662 14.6333 42.4662 17.8514V37.1008C42.466 38.2317 41.5493 39.1484 40.4184 39.1484Z" fill="#141818"/>
                                        <path d="M55.7077 39.1484C54.5768 39.1484 53.6599 38.2316 53.6599 37.1006V12.1683C53.6599 11.2084 52.8791 10.4277 51.9193 10.4277C50.9594 10.4277 50.1786 11.2084 50.1786 12.1683V37.1009C50.1786 38.2319 49.2618 39.1487 48.1308 39.1487C46.9999 39.1487 46.083 38.2319 46.083 37.1009V12.1683C46.083 8.95016 48.7011 6.33203 51.9193 6.33203C55.1374 6.33203 57.7555 8.95016 57.7555 12.1683V37.1009C57.7555 38.2317 56.8388 39.1484 55.7077 39.1484Z" fill="#141818"/>
                                        <path d="M63.2846 39.1483C62.1537 39.1483 61.2368 38.2314 61.2368 37.1005V10.2738C61.2368 7.05563 63.8549 4.4375 67.0731 4.4375C70.2912 4.4375 72.9093 7.05563 72.9093 10.2738V15.5152C72.9093 16.6461 71.9924 17.563 70.8615 17.563C69.7306 17.563 68.8137 16.6461 68.8137 15.5152V10.2738C68.8137 9.31391 68.0329 8.53313 67.0731 8.53313C66.1132 8.53313 65.3324 9.31391 65.3324 10.2738V37.1008C65.3324 38.2316 64.4157 39.1483 63.2846 39.1483Z" fill="#141818"/>
                                        <path d="M63.2846 39.1484C62.1537 39.1484 61.2368 38.2315 61.2368 37.1006V6.48493C61.2368 5.52509 60.456 4.74431 59.4962 4.74431C58.5363 4.74431 57.7555 5.52509 57.7555 6.48493V37.1007C57.7555 38.2317 56.8387 39.1485 55.7077 39.1485C54.5768 39.1485 53.6599 38.2317 53.6599 37.1007V6.48493C53.6599 3.26681 56.278 0.648682 59.4962 0.648682C62.7143 0.648682 65.3324 3.26681 65.3324 6.48493V37.1007C65.3324 38.2317 64.4157 39.1484 63.2846 39.1484Z" fill="#141818"/>
                                        <path d="M69.4985 59.8368C69.1875 59.8368 68.8722 59.766 68.5761 59.616C67.5667 59.1057 67.1624 57.8736 67.6727 56.8646C68.7044 54.8241 70.2166 53.0205 72.0458 51.6482C74.7663 49.6077 76.3905 46.3591 76.3905 42.9583V26.5286C76.3905 25.5688 75.6097 24.788 74.6499 24.788C73.69 24.788 72.9092 25.5688 72.9092 26.5286V35.3958C72.9092 36.5268 71.9925 37.4436 70.8614 37.4436C69.7305 37.4436 68.8136 36.5268 68.8136 35.3958V26.5286C68.8136 23.3105 71.4317 20.6924 74.6499 20.6924C77.868 20.6924 80.4861 23.3105 80.4861 26.5286V42.9583C80.4861 47.6413 78.2496 52.1147 74.5035 54.9246C73.175 55.921 72.0771 57.2308 71.3277 58.7125C70.9669 59.4257 70.2463 59.8368 69.4985 59.8368Z" fill="#141818"/>
                                        <path d="M48.1308 39.1484C46.9999 39.1484 46.083 38.2315 46.083 37.1006V17.8514C46.083 16.8915 45.3022 16.1108 44.3424 16.1108C43.3825 16.1108 42.6017 16.8915 42.6017 17.8514V37.1008C42.6017 38.2317 41.6849 39.1486 40.5539 39.1486C39.423 39.1486 38.5061 38.2317 38.5061 37.1008V17.8514C38.5061 14.6333 41.1242 12.0151 44.3424 12.0151C47.5605 12.0151 50.1786 14.6333 50.1786 17.8514V37.1008C50.1786 38.2317 49.2619 39.1484 48.1308 39.1484Z" fill="#141818"/>
                                        <path d="M50.5145 23.2265C44.5276 23.2265 41.8414 26.5823 40.7846 28.5532C40.652 28.8008 40.2915 28.8004 40.1592 28.5527C39.106 26.5815 36.4346 23.2263 30.4481 23.2263C23.6285 23.2263 17.6296 27.8816 17.6296 36.3826C17.6296 39.8224 18.3306 42.7694 20.2764 46.0582C24.2414 52.7604 37.3032 61.4488 39.9862 63.161C40.2829 63.3502 40.6585 63.3509 40.9556 63.1623C43.6409 61.4584 56.705 52.8129 60.701 46.0582C62.6467 42.7694 63.3429 39.8224 63.3429 36.3826C63.3426 27.8818 57.334 23.2265 50.5145 23.2265Z" fill="#F7CF47"/>
                                        <path d="M40.4717 65.3515C39.9087 65.3515 39.36 65.1912 38.8845 64.8876C37.2651 63.8543 22.9356 54.5757 18.5136 47.1012C16.4859 43.6736 15.5815 40.3681 15.5815 36.3828C15.5815 31.8025 17.1348 27.9112 20.0736 25.1295C22.7651 22.5818 26.4497 21.1787 30.448 21.1787C35.6008 21.1787 38.6947 23.3289 40.485 25.4206C42.227 23.4253 45.3383 21.1787 50.5145 21.1787C54.5133 21.1787 58.1989 22.5817 60.893 25.1289C63.8355 27.9111 65.3906 31.8026 65.3906 36.3828C65.3906 40.3736 64.4878 43.679 62.4634 47.1012C58.009 54.6312 43.6726 63.8637 42.0526 64.8917C41.5787 65.1923 41.0319 65.3515 40.4717 65.3515ZM30.4481 25.2743C25.087 25.2743 19.6773 28.7092 19.6773 36.3828C19.6773 39.6361 20.3836 42.2179 22.0386 45.0157C25.6278 51.0828 37.5862 59.1456 40.4753 61.0381C46.158 57.3676 55.8311 50.2679 58.9383 45.0157C60.59 42.2237 61.2948 39.6418 61.2948 36.3828C61.2948 28.7092 55.8805 25.2743 50.5142 25.2743C45.8461 25.2743 43.6278 27.5837 42.589 29.5211C42.1706 30.3017 41.3594 30.7865 40.472 30.7865C39.5828 30.7865 38.7709 30.3001 38.3528 29.5175C37.3186 27.582 35.1075 25.2743 30.4481 25.2743Z" fill="#141818"/>
                                    </svg>
                                </Link>
                            </div>
                            <h3 className="title">Want To Help?</h3>
                            <p className="m-b30 text-dark">Your financial support is very important for our projects.</p>
                            <Link to={"/donate"} className="btn btn-primary">Partner with us</Link>
                        </div>
                    </div>
                </div>
            </div>  
        </>
    );
};

export default FooterTop;
export const MenuListArray2 = [
    {
        title:"Home",
        to: "/",
        // classChange:"sub-menu-down",
        // content :  [
        //     {
        //         title : "Home 1",
        //         to : "/"
        //     },
        //     {
        //         title : "Home 2",
        //         to : "/index-2"
        //     },
        //     {
        //         title : "Home 3",
        //         to : "/index-3"
        //     },
        // ],
    },
    {
        title:"About Us",
        to: "/about-us",
        classChange:"sub-menu-down",
        content : [
            {
                title : "Volunteer",
                to : "/volunteer"
            },
            // {
            //     title : "Volunteer",
            //     to : "#",
            //     className:"sub-menu",
            //     content : [
            //         {
            //             title:"Volunteer",
            //             to:"/volunteer"
            //         },
            //         {
            //             title:"Become A Volunteer",
            //             to:"/become-a-volunteer"
            //         },
            //     ],
            // },
            // {
            //     title : "Faq",
            //     to : "/faq"
            // },
            // {
            //     title : "Certificates",
            //     to : "/certificates"
            // },
            // {
            //     title : "Ask A Question",
            //     to : "/ask-a-question"
            // },
            // {
            //     title : "Happy Clients",
            //     to : "/happy-clients"
            // },
            // {
            //     title : "How It Works",
            //     to : "/how-it-works"
            // },
            // {
            //     title : "Mission",
            //     to : "/mission"
            // },
            // {
            //     title : "Terms And Condition",
            //     to : "/terms-and-condition"
            // },
            // {
            //     title : "Coming Soon",
            //     to : "/coming-soon"
            // },
            // {
            //     title : "Under Maintenance",
            //     to : "/under-maintenance"
            // },
            // {
            //     title : "Error 404",
            //     to : "/error-404"
            // },
        ],
    },
    {
        title:"Projects",
        to: "/projects",
    },
    {
        title:"Blog",
        to: "/blog",
        // classChange:"sub-menu-down",
        // content : [
        //     {
        //         title:"Browse Fundraiser",
        //         to:"/browse-fundraiser"
        //     },
        //     {
        //         title:"Become A Fundraiser",
        //         to:"/become-a-fundraiser"
        //     },
        //     {
        //         title:"Fundraiser Detail",
        //         to:"/fundraiser-detail"
        //     },
        // ],
    },
    // {
    //     title:"Projects",
    //     classChange:"sub-menu-down",
    //     content : [
    //         {
    //             title:"Project",
    //             to:"/project"
    //         },
    //         {
    //             title:"Project Categories",
    //             to:"/project-categories"
    //         },
    //         {
    //             title:"Project Sidebar",
    //             to:"/project-sidebar"
    //         },
    //         {
    //             title:"Project Story",
    //             to:"/project-story"
    //         },
    //     ],
    // },
    // {
    //     title:"Blog",
    //     classChange:"sub-menu-down",
    //     content:[
    //         {
    //             title:"Blog",
    //             to:"/blog"
    //         },
    //         {
    //             title:"Blog Grid",
    //             to:"/blog-grid"
    //         },
    //         {
    //             title:"Blog List",
    //             to:"/blog-list"
    //         },
    //         {
    //             title:"Blog Details",
    //             to:"/blog-details"
    //         },
    //     ],
    // },
    {   
        title: 'Contact Us',	
        to: './contact-us',
    },

];
import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
//import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Swiper styles
import "swiper/css";

//Images
import logo1 from './../../assets/images/clients-logo/logo1.png';
import logo2 from './../../assets/images/clients-logo/logo2.png';
import logo3 from './../../assets/images/clients-logo/logo3.png';
import logo4 from './../../assets/images/clients-logo/logo4.png';
import logo5 from './../../assets/images/clients-logo/logo5.png';

// import Swiper core and required modules
import { Autoplay } from "swiper";
import { partners } from '../../data/content';

//SwiperCore.use([EffectCoverflow,Pagination]);

const PartnershipSlider = () => {
	const [images, setImages] = useState([])

	useEffect(() => {
		partners.map(d => {
			setImages(images => [...images, d.imageBW])
		})
	}, [])

    return (
        <>
            <Swiper className="clients-swiper"
				speed= {1500}
				//parallax= {true}
				slidesPerView= {5}
				spaceBetween= {30}
				loop={true}
				autoplay= {{
				   delay: 3000,
				}}
				modules={[ Autoplay ]}
				breakpoints = {{
					1191: {
                        slidesPerView: 5,
                    },
                    992: {
                        slidesPerView: 4,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    575: {
                        slidesPerView: 2,
                    },
                    320: {
                        slidesPerView: 2,
                    },
				}}
			>
				{partners.map((d,i)=>(
					<SwiperSlide key={i}>						
                        <div className="clients-logo">
                            <img
								className="logo-main"
								src={images[i] || d.imageBW}
								onMouseEnter={() => setImages(images.map((image, index) => index === i ? d.image : image))}
      							onMouseOut={() => setImages(images.map((image, index) => index === i ? d.imageBW : image))}
								alt={d.name}
							/>
                        </div>				
					</SwiperSlide>
				))}
			</Swiper>
        </>
    );
};


export default PartnershipSlider;

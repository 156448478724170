import React, {useRef} from 'react';
import {Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
//import "swiper/css/pagination";

import { Autoplay, Navigation, EffectFade, Pagination } from "swiper";

import DonateModal from '../Modal/DonateModal';
import shape1 from "../../assets/images/main-slider/slider1/shape1.svg";
import line1 from "../../assets/images/main-slider/slider1/shape-line1.svg";
import shape2 from "../../assets/images/main-slider/slider1/shape2.svg";
import line2 from "../../assets/images/main-slider/slider1/shape-line2.svg";
import { rootImagePath } from '../../data/content';

const slide1 = `${rootImagePath}/general/home/psalms-bg.jpg`;
const slide2 = `${rootImagePath}/general/home/gco-bg.jpeg`;
const slide3 = `${rootImagePath}/general/home/idbc-bg.jpg`;
const pic1 = `${rootImagePath}/general/home/psalms-image-1.jpeg`;
const pic2 = `${rootImagePath}/general/home/psalms-image-2.jpeg`;
const pic3 = `${rootImagePath}/general/home/psalms-image-3.jpeg`;
const pic4 = `${rootImagePath}/general/home/gco-image-1.jpg`;
const pic5 = `${rootImagePath}/general/home/gco-image-2.jpeg`;
const pic6 = `${rootImagePath}/general/home/gco-image-3.jpeg`;
const pic7 = `${rootImagePath}/general/home/idbc-image-1.jpg`;
const pic8 = `${rootImagePath}/general/home/idbc-image-2.jpg`;
const pic9 = `${rootImagePath}/general/home/idbc-image-3.jpg`;

const dataBlog = [
	{title1:"Psalms Outreach", title2:"Delivering relief to our world, one community at a time",baner:slide1, img2: pic1, img3: pic2, img4:pic3 },
	{title1:"Girl Child Outreach", title2:"Empowering Girls: Shaping Futures, Breaking Barriers.",baner:slide2, img2: pic4, img3: pic5, img4:pic6 },
	{title1:"International day of the Boy Child", title2:"Nurturing Boys into Well-rounded and Successful Men",baner:slide3, img2: pic7, img3: pic8, img4: pic9},
];

const Mainslider2 = ({onShowDonate}) => {
    const navigationPrevRef = React.useRef(null)
	const navigationNextRef = React.useRef(null)
    //const paginationRef = React.useRef(null)
	const modalRef = useRef(null);
    return (
        <>
            <Swiper className="main-slider"						
				speed={1500}
				parallax={true}
				slidesPerView={1}
                effect="fade"
				spaceBetween={0}
				loop={true}
				autoplay={{
				   delay: 6000,
				}}
                // pagination={{ clickable: true }}
                onSwiper={(swiper) => {
					setTimeout(() => {
						// if (!swiper.destroyed) {
							swiper.params.navigation.prevEl = navigationPrevRef.current
							swiper.params.navigation.nextEl = navigationNextRef.current
							swiper.navigation.destroy()
							swiper.navigation.init()
							swiper.navigation.update()
						// }
					})
				}}
                // pagination= {{
                //     el= {.swiper-pagination}
                //     clickable= {true}
                // }}
				modules={[ Autoplay, Navigation, EffectFade ]}
				
			>	
				{dataBlog.map((d,i)=>(
					<SwiperSlide key={i}>						
                       <div className="banner-inner" style={{backgroundImage: "url("+ d.baner +")"}}>
							<div className="container-fluid">
								<div className="row">
									<div className="col-lg-6 col-md-12">
										<div className="banner-content">
											<div className="top-content">
												<h5 className="sub-title text-secondary">{d.title1}</h5>
												<h1 className="title">{d.title2} </h1>
												<p>
													We believe in transforming our community, one family at a time, one individual at a time, and are committed to social change and advocacy through Community Development, Health, Education, Empowerment, Food and Water (CHEEF).
												</p>
												<div className="d-flex align-items-center">
													<Link to={"#"} className="btn btn-primary btnhover1" 
														//onClick={()=>onShowDonate(true)}
														onClick={() => {modalRef.current.handleModalOpen(); }}
													>
														<span>Donate</span>
														<i className="flaticon-heart text-secondary ms-3"></i>
													</Link>
													<Link to={"/about-us"} className="btn btn-secondary btnhover2">Learn More <i className="flaticon-right-arrows ms-3"></i></Link>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-md-12">
										<div className="banner-media row gx-4">
											<div className="col-5">
												<div className="main-img1">
													<img src={d.img2} alt="" />
												</div>
												<div className="main-img2">
													<img src={d.img3} alt="" />
												</div>
											</div>
											<div className="col-7">	
												<div className="main-img3">
													<img src={d.img4} alt="" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<img className="shape1" src={shape1} alt="" />
							<img className="shape2" src={line1} alt="" />
							<img className="shape3" src={shape2} alt="" />
							<img className="shape4" src={line2} alt="" />
						</div>			
					</SwiperSlide>
				))}			
                
                <div className="bottom-wrapper">
					<ul className="social-list">
						<li><a href="https://www.facebook.com/UduakCharlesDiaries" target="_blank" rel="nofollow noreferrer noopener">Facebook</a></li>
						<li><a href="https://twitter.com/UCDiaries" target="_blank" rel="nofollow noreferrer noopener">Twitter</a></li>
						<li><a href="https://www.instagram.com/uduakcharlesdiaries" target="_blank" rel="nofollow noreferrer noopener">Instagram</a></li>
					</ul>
					<div className="btn-wrapper">
						<div className="main-btn main-btn-prev" ref={navigationPrevRef}><i className="fa fa-angle-left"></i></div>
						<div className="main-btn main-btn-next" ref={navigationNextRef}><i className="fa fa-angle-right"></i></div>
					</div>
				</div>
			</Swiper>
			<DonateModal 
                ref={modalRef}
            />
        </>
    );
};


export default Mainslider2;
import React, { useEffect, useState } from 'react';
import {Link, Navigate, useParams} from 'react-router-dom';
import { blogPosts } from '../data/content';

//import pic1 from '../assets/images/blog/recent-blog/pic1.jpg';
//import pic2 from '../assets/images/blog/recent-blog/pic2.jpg';
//import pic3 from '../assets/images/blog/recent-blog/pic3.jpg';
//import pic4 from '../assets/images/blog/recent-blog/pic4.jpg';
import NewsSlider from '../components/Home/NewsSlider';
import UpdateBlog from '../components/Home/UpdateBlog';
import BlogDetailsLeftBar from '../components/BlogDetailsLeftBar';
import { IMAGES } from '../constant/theme';
import { format, parse } from 'date-fns';

const widgetBlog = [
    {title:"New vaccine for cattle calf loss learned.", image:IMAGES.Recentblog1},
    {title:"He Created the Web. Now He’s Out to Remake.", image:IMAGES.Recentblog2},
    {title:"Partnering to create a community.", image:IMAGES.Recentblog3},
    {title:"Best & Less published their supplier lists.", image:IMAGES.Recentblog4},
];

// const tagData = [
//     { title:"Business"},
//     { title:"Corporate"},
//     { title:"Blog"},
//     { title:"Marketing"},
//     { title:"Creative"},
//     { title:"Web"},
//     { title:"Workers"},
//     { title:"Modern"},
// ];

const BlogDetails = () => {
    const [tagData, setTagData] = useState([]);
    const [tagsMap, setTagsMap] = useState({});
    const [categories, setCategories] = useState({});
    const [blogData, setBlogData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { slug } = useParams();

    useEffect(() => {
        const blogData = blogPosts.find((item) => item.slug === slug);
        setBlogData(blogData);
        setIsLoading(false);
    }, [slug]);

    useEffect(() => {
        let currentTags = {}
        let categories = []
        blogPosts.map((item) => {
            item.tags.map((tag) => {
                if (!currentTags[tag]) {
                    currentTags[tag] = 1;
                } else {
                    currentTags[tag] += 1;
                }
            })
            if (!categories.includes(item.category)) {
                categories.push(item.category)
            }
        })
        setCategories(categories);
        setTagData(Object.entries(currentTags).sort((a, b) => b[1] - a[1]).slice(0, 10).map((item) => ({
            title: item[0]
        })));
        setTagsMap(currentTags);
    }, []);

    return (
        <>
            {!blogData && !isLoading && <Navigate to="/404" />}
            {blogData && !isLoading &&
            <div className="page-content bg-white">
                <section className="content-inner-2">
                    <div className="container">			
                        <div className="row ">
                            <div className="col-xl-8 col-lg-8 m-b30">
                                <BlogDetailsLeftBar data={blogData} />
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <aside className="side-bar sticky-top">
                                    {/* <div className="widget style-1">
                                        <div className="widget-title">
                                            <h5 className="title">Search</h5>
                                        </div>
                                        <div className="search-bx">
                                            <form role="search">
                                                <div className="input-group">
                                                    <input name="text" className="form-control" placeholder="Search Here..." type="text" />
                                                    <span className="input-group-btn">
                                                        <button type="submit" className="btn btn-primary sharp radius-no"><i className="la la-search scale3"></i></button>
                                                    </span> 
                                                </div>
                                            </form>
                                        </div>
                                    </div> */}
                                    <div className="widget style-1 widget_categories">
                                        <div className="widget-title">
                                            <h5 className="title">Categories</h5>
                                        </div>
                                        <ul>
                                            {categories.map((item, index) => (
                                                <li className="cat-item" key={index}><Link to={"#"}>{item}</Link></li>      
                                            ))}
                                            {/* <li className="cat-item"><Link to={"#"}>business (10)</Link></li>
                                            <li className="cat-item"><Link to={"#"}>Case Study (13)</Link></li>
                                            <li className="cat-item"><Link to={"#"}>Insights (9)</Link></li>
                                            <li className="cat-item"><Link to={"#"}>World (3)</Link></li>
                                            <li className="cat-item"><Link to={"#"}>Tax Solutions (12)</Link></li> 
                                            <li className="cat-item"><Link to={"#"}>Creative (6)</Link></li>  */}
                                        </ul>
                                    </div>
                                    <div className="widget style-1 recent-posts-entry">
                                        <div className="widget-title">
                                            <h5 className="title">Recent Post</h5>
                                        </div>
                                        <div className="widget-post-bx">
                                            {blogPosts
                                            .filter((item) => item.slug !== slug)
                                            .sort((a, b) => b.date - a.date)
                                            .slice(0, 3)
                                            .map((item, ind)=>(
                                                <div className="widget-post clearfix" key={ind}>
                                                    <div className="dz-media"> 
                                                        <img src={item.image} alt="" />
                                                    </div>
                                                    <div className="dz-info">
                                                        <h6 className="title"><Link to={`/${item.slug}`}>{item.title}</Link></h6>
                                                        <div className="dz-meta">
                                                            <ul>
                                                                <li className="post-date"><i className="fa-solid fa-calendar-days"></i>{format(parse(item.date, "yyyy-MM-dd", new Date()), "d MMMM, yyyy")}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    
                                    <div className="widget style-1 widget_tag_cloud">
                                        <div className="widget-title">
                                            <h5 className="title">Popular Tags</h5>
                                        </div>
                                        <div className="tagcloud"> 
                                            {tagData.map((item, ind) =>
                                                <Link to={"#"} key={ind}>{item.title}</Link>
                                            )}                                            
                                        </div>
                                    </div>
                                    
                                </aside>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content-inner-2">
                    <div className="container">
                        <div className="section-head">
                            <h2 className="title">Related Articles</h2>
                        </div>
                        <NewsSlider slugToSkip={blogData.slug} />
                    </div>
                </section>
                <div className="call-action style-1 content-inner-1">
				    <div className="container">
                        <UpdateBlog />
                    </div>
                </div>
            </div>
            }
        </>
    );
};

export default BlogDetails;
export const rootImagePath = "https://cdn.media.uduakcharlesdiaries.com/content"

export const blogPosts = [
    {
        date: "2016-02-14",
        title: "The Girl Child Outreach",
        mainFeature: false,
        featured: false,
        content: `Every child deserves the best irrespective of their gender. That's why Uduak Charles Diaries is taking out time to build up the girl child. The girl child outreach is aimed at educating young girls on various health issues encompassing the female gender. Issues such as reproductive health as well as sex education. The girls also were given sanitary pads and were taught on menstrual health.
The team took out the 14th of February 2016 to spend time teaching and conversing with 400 girls from Uyo High School.
Beyond touching the lives of the girls, the contributions and support of the team and friends brought an enlightened future and redefined purpose to the girls. 
Thank you.	
Long Live #Psalms
Long Live #UduakCharlesDiaries
Long Live our Girl Child`,
        excerpt: "Every child deserves the best irrespective of their gender.",
        slug: "the-girl-child-outreach",
        tags: ["Girl Child Outreach", "Psalms", "UduakCharlesDiaries"],
        category: "Girl Child Outreach",
        comments: [],
        image: `${rootImagePath}/2021/gco-uyo-fstc-2021/girl-child-outreach-16.jpeg`,
        author: "Jennifer Pius",
    },
    {
        date: "2019-05-21",
        title: "International Day of the Boy Child: Honoring the Extraordinary Guys in Our Lives!",
        previewTitle: "International Day of the Boy Child",
        mainFeature: false,
        featured: false,
        content: `When was the last time you paused to celebrate the remarkable men in your life? Sons who bring boundless joy, brothers who have your back, friends who light up your world, and husbands who fill your heart with love. If you haven't taken that moment yet, let us tell you: You're missing out! Why, you ask? Because today is a day of immense significance—it's the International Day of the Boy Child! It's a time to uplift and cherish the incredible boys who shape our world and hold the promise of a bright future. So, let's cast aside any hesitations and dive into the festivities!
Here at Uduak Charles Diaries, We gathered the enthusiastic Calabar Team, and had heartfelt conversations with boys that revolved around career aspirations, family life, and the profound journey of priesthood. The exchange of wisdom and experiences created an atmosphere buzzing with inspiration.
Now, it's your turn to join the festivities. Can you take a moment to celebrate a special guy in your life? Set aside intentional time to honor them in your own unique way. Whether it's a heartfelt post, a thoughtful gift, or a grand gesture, let them know just how much they mean to you. Feel free to tag us in your celebratory moments so we can share in the joy!

#picoftheday
#explorepage
#ucdif
#internationaldayoftheboychild
#UduakCharlesDiaries

For Partnerships and Support,
Please contact us on:
support@uduakcharlesdiaries.com`,
        excerpt: "When was the last time you paused to celebrate the men in your life?",
        slug: "the-girl-child-outreach",
        tags: ["picoftheday", "explorepage", "ucdif", "internationaldayoftheboychild", "UduakCharlesDiaries"],
        category: "International Day of the Boy Child",
        comments: [],
        image: `${rootImagePath}/2021/idbc-2021/idbc-uduak-charles-2021-16.jpg`,
        author: "",
    },
    {
        date: "2022-02-16",
        title: "Keke Outreach 2022: Spreading Love One Tank at a Time!",
        mainFeature: true,
        featured: false,
        content: `Today, as the sun rises, a humble Keke man sets out on his daily hustle, driven by a heartfelt mission. His goal? To earn enough money for school fees before the fee drive begins. For him, this is his Valentine's Day, a testament to his unwavering commitment.
In the bustling streets, another Keke man, donning the hat of a loving brother, embarks on his journey, striving to send money to his sister. Today, like any other day, they don't expect ice cream, chocolate, or cake to be gifted to them. But love, dear friends, transcends material luxuries. 
Today, we reached out to 20 Keke men and women, filling their tanks with an abundance of care and compassion. Picture it: 20 full tanks of love, akin to the biblical tale of the prophet who filled a woman's vessels, miraculously turning scarcity into abundance.
For them, this will be their Valentine's Day, a day when their burdens are lightened, and their spirits soar. We invite you, dear reader, to join us in this act of kindness. Leave a little change with Keke drivers, pay 100 when the fare is only 50, and pause to offer them a warm smile as you ask about their day. Let love permeate the air as we walk alongside it, feeling the gratitude of the earth beneath our feet.
Together, let us continue to illuminate the world with love, one tank at a time.

#picoftheday
#explorepage
#kekeoutreach2022
#UduakCharlesDiaries
Visit the gallery for more photo speaks`,
        excerpt: "Today, as the sun rises, a humble Keke man sets out on his daily hustle",
        slug: "keke-outreach-2022",
        tags: ["picoftheday", "explorepage", "kekeoutreach2022", "UduakCharlesDiaries"],
        category: "Outreach",
        comments: [],
        image: `${rootImagePath}/2022/keke-outreach/keke-outreach-1.jpeg`,
        author: "",
    },
    {
        date: "2020-09-19",
        title: "Psalms Outreach 2020 Day 1",
        mainFeature: false,
        featured: false,
        content: `
In the spirit of unity, our organization embarked on an extraordinary journey during Day 1 of Psalms Outreach 2020. Our mission? To create a better school community for all.
With sleeves rolled up and determination in our hearts, we ventured into the task at hand. The overgrown grasses that hindered the children's learning experience were swiftly cut down, paving the way for a clear path to knowledge. No longer will the students waste precious time tending to the fields when they should be immersed in their studies. Physical exhaustion will no longer impede their mental growth.
But that's not all. We set out to transform the classrooms into havens of comfort and inspiration. Dilapidated floors received the care they desperately needed, rejuvenated with repairs that breathe new life into the learning space. And with great delight, we delivered brand-new desks to ensure that every child has a place to sit, free from discomfort and distraction. Now, they can focus on expanding their minds without the obstacles that once hindered their progress.
To further enhance the school's identity and instill a sense of pride, we crafted a striking new signboard, proudly displaying the name of the institution. And as a symbol of unity and patriotism, the Nigerian flag now waves proudly in the schoolyard. These small yet impactful gestures uplift the spirits of students and teachers alike, fostering an environment where education thrives.
As we embarked on this transformative journey during Psalms 2020, we became the storytellers of change. And today, we share this incredible story with you, inviting you to witness the power of collective action and compassion.
To delve deeper into the chapters of Psalms 2020, we invite you to visit our website by clicking the link in our bio. Immerse yourself in the tales of unity, empowerment, and transformation.
Join us in this incredible journey by using the hashtags #psalms2020, #psalmsucd, #abetterlife, #ucdif, #instagood, #explore, #exploremore, #foundation, #charity, and #unitednations. Let us unite our voices and hearts as we illuminate the path to a brighter future.
        `,
        excerpt: "In the spirit of unity, our organization embarked on an extraordinary journey",
        slug: "psalms-2020-day-1",
        tags: ["psalms2020", "psalmsucd", "abetterlife", "ucdif", "instagood", "explore", "exploremore", "foundation", "charity", "unitednations"],
        category: "Psalms Outreach",
        comments: [],
        image: `${rootImagePath}/2020/psalms-day-1/psalms-day-1-1.jpeg`,
        author: "",
    },
    {
        date: "2020-09-20",
        title: "Psalms Outreach 2020 Day 2",
        mainFeature: false,
        featured: false,
        content: `
You'd agree with us that many of our mothers and fathers especially our elderly ones rarely go to the hospital for a check-up except something is really, really, really wrong.
Many don't monitor their blood pressure and health.
Many keep working and can't afford to get the necessary drugs at that age.
Malaria is common to Africa. It's a heartbreaking truth that treatable illnesses, such as malaria, claim lives because access to medication remains out of reach for many.
Psalms 2020 took care of our elders, our mothers and fathers. Their health is important to us. The community wasn't left out though.
There was a medical team with qualified medical personnels on ground for a whole day to attend to many members of the community. The drugs were prescribed correctly and given free of charge. People received free counselling on their health too.
To have a better Life, one must first have life. Health is Life. Good health is a better life
You can join us In creating better lives for people . You can choose to donate, partner or volunteer.
For enquires, you can send us a mail: info@uduakcharlesdiaries.com
Or
Send us a direct message , we will respond.
Visit our website: www.uduakcharlesdiaries.com
#psalmsucd 
#community
#UDCiF
#abetterlife #psalms2020
#abetterlifeucd
#healthylifestyle
#explore
#instagood
#UduakCharlesDiaries
        `,
        excerpt: "You'd agree with us that many of our mothers and fathers especially our elderly ones",
        slug: "psalms-2020-day-2",
        tags: ["psalmsucd", "community", "UDCiF", "abetterlife", "psalms2020", "abetterlifeucd", "healthylifestyle", "explore", "instagood", "UduakCharlesDiaries"],
        category: "Psalms Outreach",
        comments: [],
        image: `${rootImagePath}/2020/psalms-day-2/psalms-2020-day-2-15.JPG`,
        author: "",
    },
    {
        date: "2020-09-21",
        title: "Psalms Outreach 2020 Day 3",
        mainFeature: false,
        featured: false,
        content: `Close your eyes and picture this scene: a child races toward you, bubbling with excitement. With a mischievous grin and a makeshift laboratory coat, she exclaims, "Hey, look at me!" And as you lift your gaze, you witness her radiant smile, filled with hope and determination. "I want to be a doctor," she declares, "to prevent people from falling ill." Can you help but beam with pride?
Now, imagine this: many school children cannot afford school uniforms. And for those who can, their worn and faded attire reflects their inability to constantly replace them with newer, brighter ones. But here's the truth we must embrace—the journey to becoming a doctor, an engineer, or any other profession begins with a school uniform. Even if the job doesn't require one, the foundation of education is built upon the unity and equality fostered by uniforms.
Psalms 2020 went beyond beautifying classrooms, cleaning communities, and providing free medical services. We recognized that to shape a brighter future, we had to address the pressing need for school uniforms and learning materials. With great joy, we distributed 300 school uniforms to each student, accompanied by essential educational resources—completely free of charge. Today, the future is aglow with optimism, smiles, and a renewed sense of hope, all thanks to your remarkable vision in 2020 and the transformative power of Psalms.
As we eagerly anticipate Psalms 2021, we celebrate the countless lives that have been forever improved. But this journey is not exclusive to us—we invite you to join us, to be an integral part of this transformative movement. Your organization can partner with us, your individual support can make an incredible impact. Whether through donations, volunteering, or simply spreading the word, you possess the ability to make lives better with whatever resources you have at your disposal.
To delve into the inspiring stories of Psalms and witness the incredible impact we have achieved over the past five years, we invite you to visit our website at www.uduakcharlesdiaries.com. It's a testament to the transformative power of compassion and unity.
As we embark on a new month, the Psalms team extends warm wishes to you all. May this month be filled with boundless opportunities, continued growth, and a steadfast commitment to creating a better tomorrow.
Join us in the ongoing journey of Psalms.

#psalmsucd
#ucdif
#march
#explorepage
#instagood
#exploremore
#psalms2020
#abetterlifeucd
#UduakCharlesDiaries

Visit our gallery for more impactful moments that speak volumes.
        `,
        excerpt: "Close your eyes and picture this scene: a child races toward you, bubbling with excitement",
        slug: "psalms-2020-day-3",
        tags: ["psalmsucd", "community", "UDCiF", "abetterlife", "psalms2020", "abetterlifeucd", "healthylifestyle", "explore", "instagood", "UduakCharlesDiaries"],
        category: "Psalms Outreach",
        comments: [],
        image: `${rootImagePath}/2020/psalms-day-3/psalms-day-3-2.jpeg`,
        author: "",
    },
    {
        date: "2020-02-10",
        title: "Our Battle Against Covid-19 with Union Bank",
        mainFeature: false,
        featured: true,
        content: `Imagine a world where thoughts of personal gain never crossed our minds, where our lives were dedicated solely to serving our local communities. A world where even the most remote and forgotten corners were infused with hope and assurance for a secure tomorrow. This vision birthed our Foundation—a testament to our unwavering commitment.
Today, our fearless team embarked on a crucial mission to Ekpene Ukim, armed with the invaluable support of Union Bank, as we waged a fierce battle against the relentless pandemic known as Covid-19. What we discovered left us speechless—these resilient souls possessed zero knowledge about the elusive Corona. Social distancing and sanitizers were foreign concepts, alien to their daily lives. We cautiously approached them, maintaining a safe distance, and endeavored to educate them on the proper usage of sanitizers. Though our resources were limited, we were able to reach out to 50 market women and men, leaving an indelible mark of awareness.
Our sights were set on the bustling marketplace, the epicenter of commerce. We recognized that if even a semblance of hygiene could be observed there, we would take a crucial step towards safeguarding our collective well-being. Admittedly, our actions may seem small in the grand scheme of things, but we refuse to simply sit back and create mere social media posts.
Our unwavering dedication to rural communities is unyielding, and I firmly believe that through our humble efforts and sustained partnerships—such as the invaluable support from UNION BANK and Nirvanix—we will forge a difference, igniting a transformative spark in the lives of those we touch.
To witness the captivating moments that encapsulate our journey, I invite you to explore our gallery—a visual testimony to the power of unity and resilience.
Together, let us unleash hope in the face of adversity.

#UnleashingHope
#UnionBankSupports
#NirvanixCollaboration
#Covid19Warriors
#RuralCommunitiesMatter
#UpliftingThroughAction
        `,
        excerpt: "Imagine a world where thoughts of personal gain never crossed our minds",
        slug: "our-battle-against-covid-19-with-union-bank",
        tags: ["UnleashingHope", "UnionBankSupports", "NirvanixCollaboration", "Covid19Warriors", "RuralCommunitiesMatter", "UpliftingThroughAction"],
        category: "COVID-19",
        comments: [],
        image: `${rootImagePath}/2020/fight-covid-19/fight-covid-19-15.jpeg`,
        author: "",
    },
    {
        date: "2022-02-15",
        title: "The Girl Child Outreach 2022",
        mainFeature: true,
        featured: true,
        content: `You may think that Valentine's Day is only for lovers. Well, you're wrong. Valentine's Day is for love-hers too. We are love-hers, advocates for womanity. Did you know that girls can start menstruating as early as 9 years old? Every month, for an average of 3-7 days, girls go through menstruation. Considering that it's a natural part of life, one would expect menstrual pads to be as affordable as a sachet of water. But no, the minimum cost for a pad is 350. 
On February 14, 2021, our foundation showed love to 150 ""hers"" (girls) at Federal Science and Technical College in Uyo, Akwa Ibom State. We expressed our love in various ways, using all the love languages. We spent quality time together for about three hours, engaging in conversations about menstrual hygiene, mental health, entrepreneurship, and God's plan for their lives. We affirmed their power, beauty, and intelligence. We wanted them to know that there is no shame in experiencing monthly flows, that they are not unclean, and that they can dare to dream big and even bigger.
At the end of the outreach, each girl received a Longrich pad. A total of 150 girls were provided with pads. While we are happy that we touched their hearts, we are even more excited that we touched their lives. Now, 150 girls will have a less painful menstruation, they won't feel ashamed during their period, and they are actively working on improving their mental health. These 150 girls are among the future great women, with bigger visions, dreams, and the courage to pursue them.
Our act of service to womanity was to love them in the way we did. It was truly an act of love. When we say ""we,"" we mainly refer to all the volunteers and generous donors who contributed to this cause.
Remember, to love is human, but to love-her is divine!

#GirlChildOutreach2021
#Day2
#TheGirlChild
#UduakCharlesDiaries
        `,
        excerpt: "You may think that Valentine's Day is only for lovers.",
        slug: "girl-child-outreach-2021",
        tags: ["GirlChildOutreach2021", "Day2", "TheGirlChild", "UduakCharlesDiaries"],
        category: "Girl Child Outreach",
        comments: [],
        image: `${rootImagePath}/2022/gco-adiahaobong-2022/girl-child-uyo-26.jpg`,
        author: "",
    },
    {
        date: "2019-09-18",
        title: "Renew",
        mainFeature: false,
        featured: false,
        content: `In the words of Dr. Uduak Charles: ""It's time for the youth to get their hands dirty, not with acts of violence and vices, but with acts of love and kindness.""
The world is filled with pain and stories that can resurrect those pains, but very few offer hope and light to guide us out of the darkness. In UCDiaries, we replace ruins with rebuilt walls, tears with renewed smiles, and pain with restored hope. This is truly our purpose for existence. There are deep scars that cause unseen hurts and pains, and we provide an opportunity for healing and a hopeful path forward. Our message of hope reached the Ibianang Ediene Community in Ikono Local Government Area of Akwa Ibom State on September 14, 2019.
To rewrite the past, we must renew the present. UCDiaries has once again recorded a story as it extends acts of love to the elderly, the youth, and the children. This is significant and important to us because it reminds us of our roots and shows us the direction we are heading. By creating new things, we renew the old and transform its essence and form. While this may not rectify past wrongs, it empowers us to do what is right.
When we see a need, we take the lead and bring about change. This is the essence of UCDiaries. We convey a message of hope, and since hope cannot be seen, we inspire it through tangible acts of love and kindness. At the end of the day, you will discover that the ultimate goal of all these endeavors is to make you an inspiration to others and ultimately to the world. It all begins one step at a time. Our good deeds are like an investment for the future, so when it pays off, future generations will reap its benefits.
Doing good one day at a time, to one person at a time, creates a ripple effect like a stone thrown into a stream. It initiates a change that is not only positive but contagious because deep down inside, we are all human beings who breathe and bleed, who feel and empathize, and who recognize our universal language - love. UCDiaries is more than just a source of relief from pain and suffering; we help you see the sunshine and rain. UCDiaries is not only concerned with concealing scars; we also uncover your unique talents and abilities. UCDiaries is not just a foundation; we are a community of ""Youmans"" and we tell a story.
Alone, we are like stars, but together we form the sky. Alone, we may bear scars, but together our dots connect to tell the story of life. We are Uduak Charles Diaries, and renewal is at the core of what we do. It's up to you to join us and help us rewrite more stories, because at the end of the day, we all have a story to tell.
        `,
        excerpt: "The world is filled with pain and stories that can resurrect those pains, but very few offer hope",
        slug: "project-renew",
        tags: ["renew", "UduakCharlesDiaries"],
        category: "Uduak Charles Diaries",
        comments: [],
        image: `${rootImagePath}/general/blog/renew-uduakcharlesdiaries.com.jpeg`,
        author: "",
    },
    {
        date: "2020-01-07",
        title: "Call For Partnership (Valentine 2020)",
        mainFeature: false,
        featured: false,
        content: `Are you ready to make a difference in a unique way?
Are you willing to take that extra step this February?
As we celebrate our 5th year of purposefully transforming local communities, we gratefully acknowledge God's unwavering faithfulness. With this milestone, we aspire to accomplish even more with your valued support and esteemed partnership.

Can you contribute towards providing sanitary pads for girl children? We are in need of 100 of them!
Do you own a makeup studio? How about sharing your expertise with a few kids this February?
Are you a skilled baker? Could you teach aspiring young bakers the art of baking?
Do you run a computer school? Can you enroll a few kids and open doors to a digital world?
Are you a barber or tailor? Could you mentor and guide young minds for a while?

This year, our vision extends beyond the confines of the classroom. We aim to equip them with essential survival skills. Why not become an integral part of this transformative journey?
        `,
        excerpt: "Are you ready to make a difference in a unique way?",
        slug: "call-for-partnership",
        tags: ["partnership", "UduakCharlesDiaries"],
        category: "Partnership",
        comments: [],
        image: `${rootImagePath}/general/blog/Valentine-2020-Uduak-Charles-Diaries.jpeg`,
        author: "",
    },
    {
        date: "2018-09-07",
        title: "Rebuild the walls",
        mainFeature: false,
        featured: false,
        content: `
According to an African proverb, ""If you want to go fast, go alone; if you want to go far, go together”. In our bid to help communities and people in need, we have to rewrite their stories and that’s why we are a diary with many stories rewritten.  However, we have always accomplished this together, with the aim of not only forging a prosperous future by rewriting the past and living in the present, but of making so much quality and life brim in the hearts and eyes of each individual we encounter so we can all be the building blocks for our tomorrow.

From the ruins of a forsaken system, we lay the foundation for a new beginning by rebuilding the walls. This was not done literally but if you look closely you’ll see. The floors were broken with potholes in them, we filled the holes and every possibility of wounds (both visible and hidden). The walls were pale and bleaching with no motivation in them, we painted the walls and designed it to speak “welcome to school – the place for learning”. The boards were white and could hardly bear the words that could shape these young minds, we blackened the boards so the children could see with a clean perspective – white in colour. The desks were insufficient and the floor became their seats, we made desks and tables to enable them to learn in comfort. The environment was bushy and sent vibes of desolation, we cleared the environment and sent vibes conducive enough for learning. We rebuilt the broken walls.

We can make a city gate and fence its treasures but we can never live to our best potentials if we are caged and have no model of success. The children are an investment for the world to be a better place and if we look closely there’s a kid in all of us. But when these children grow up in societies that point the finger more and paint the finger less, in societies that criticizes more than help them learn, in societies that show no roadmap to the future where they can be all they dream then they start feeling inadequate, undeserving, indolent and unworthy of the dreams that they nurtured in their hearts. We choose to rebuild the walls. In the words of a poet:

""The child carries the seed of greatness deep inside
That's why greatness is never born a man, but a child
However, if not protected, these seeds could be exposed to comparison with trees
And what it does is kill their dreams and potentials before they even have the chance to live or truly be""

There is a reason why children are referred to as posterity—they represent the future and serve as a means for us to share tales of greatness that resonate for eternity. But if we continue to destroy and undermine what we once were, are we not being hypocritical? Sooner or later, we will realize that we have been planning our own funerals, as the seeds of hate and the pangs of regret awaken us to our six feet deep burial.

We can make the difference one step at a time
If we help one child at a time, one day at a time
Let’s together paint a picture of a smile
That widens to a grin upon the faces of every child
Because when we look at the world with innocent eyes, everything is divine
And nothing spells innocent like C.H.I.L.D.

So stand with us shoulder to shoulder, and support us hand in hand to build from the ruins and debris; join UCDiaries and together let’s rebuild the walls.
        `,
        excerpt: "If you want to go fast, go alone; if you want to go far, go together",
        slug: "rebuild-the-walls",
        tags: ["rebuild", "UduakCharlesDiaries"],
        category: "Uduak Charles Diaries",
        comments: [],
        image: `${rootImagePath}/general/blog/rebuild-the-walls-udukcharlesdiaries.com.jpeg`,
        author: "Jennifer Pius",
    },
    {
        date: "2018-09-12",
        title: "Raise a Flag",
        mainFeature: false,
        featured: false,
        content: `
A white flag in the air represents surrender, especially in the event of a war. But what happens when we hoist a flag colored in simple beauty into the bright morning sky, with acts of love and kindness and a passion for service and change? Is that too, a war that we are surrendering to? We never stop until it is done, we have a mandate and it is to restore hope to lives.
On the 14th day of September 2019 on a school premise, the Uduak Charles Diaries Foundation did something that was very significant in its simplicity. After a series of community services and acts of kindness, the UCDiaries brought down the old Nigerian flag and hoisted a new one that stood clean, soared high, and basked in the glory of the morning sun, check out www.actionac.net/. The colours – green and white – signified the beginning of a new phase for the school and her pupils, and more importantly, for a new Nigeria. a new Nigeria where things work and her leaders are sensitive to solving her problems; those leaders are the ones whose lives we touched, whose tears we wiped, whose bodies we clothed, whose classrooms we redesigned, whose bellies we fed, whose smile we renewed and whose hope we restored on that fateful day.
With every soldier standing around the base of the flag and looking up into the sky, we spelled hope in that singular act of raising a flag. Many of us have experienced surrender when raising flags that were white, bearing no message other than defeat. But UCDiaries raised a flag with colours that speak of a bright future, of hope, and of a new Nigeria.
We may not be able to help all children in need, but we can help one child today who will emerge as a leader tomorrow and help more children. Together, we can help more than one child at a time; with your support, we can record more stories of change in our diary and we can create the change that we so desire.
We raise a flag with a message boldly inscribed – HOPE FOR TOMORROW! What message does yours carry?
        `,
        excerpt: "A white flag in the air represents surrender, especially in the event of a war",
        slug: "raise-a-flag",
        tags: ["RaiseAFlag", "UduakCharlesDiaries"],
        category: "Uduak Charles Diaries",
        comments: [],
        image: `${rootImagePath}/general/blog/psalms-2019-raise-a-flag-uduakcharlesdiaries.com.jpeg`,
        author: "Jennifer Pius",
    },
    {
        date: "2018-12-21",
        title: "How we give back",
        mainFeature: false,
        featured: false,
        content: `
Education is the bedrock of every society that seeks to grow and advance. Unfortunately not many children in the rural villages are opportune to access quality education due to inadequate or a complete lack of good teaching facilities.
Luckily, we are blessed with a team of assertive volunteers from different works of life who come in with all the energy and ginger needed to work the talk. Why complain when we can make a change no matter how little?
The picture above captures the moment our volunteer sets to work with a huge smile plastered on her face to blacken the boards used for teaching in one of the community schools we visited, check out http://monderlaw.com/. With a properly darkened board, the teachers can write legibly and the pupils can easily read and copy notes to improve upon their studies.
What better way is there to show the love we constantly talk about and pass the message of service to humanity than taking practical steps to fix what we can fix?
If we can, you too can. We encourage you to invest a few hours of your time into giving back to society by joining us in our next outreach.    
        `,
        excerpt: "Education is the bedrock of every society that seeks to grow and advance",
        slug: "how-we-give-back",
        tags: ["GiveBack", "UduakCharlesDiaries"],
        category: "Uduak Charles Diaries",
        comments: [],
        image: `${rootImagePath}/general/blog/education-students.jpg`,
        author: "Jennifer Pius",
    },
    {
        date: "2018-11-01",
        title: "Community Service",
        mainFeature: false,
        featured: false,
        content: `
Gone are the days when Africa was a code word for slavery. The labours of her heroes past did set her free. More importantly, what makes us free is the quality of the things we do with the opportunities we are blessed with.
It is our duty now to make room for shared experiences and joy and this is embedded in our acts of service to humanity.
Every problem may not be solved; every tear may not be wiped but with every progress we make, with every step we take as a team, we get closer to bettering lives and impacting humanity one community at a time.
We take pride in rolling up our sleeves, getting our hands dirty, and settling down to brass tacks. Just as we love to give out supplies to assist these communities, we derive joy in performing actual services such as filling the pots of the elderly with clean water, cleaning town halls, mowing the grasses at the village squares, filling potholes and providing tailoring and grooming services, etc to the inhabitants of our focus community. This we believe is the core of community service.
You and I can make a huge difference if we come together to assess the needs of these communities and pull resources together to create a change.
We aren’t just preaching service to humanity; we practice it through direct services to these communities.
Our strategy is to Solve Multiple Issues in Life Everyday – SMILE
        `,
        excerpt: "Gone are the days when Africa was a code word for slavery",
        slug: "community-service",
        tags: ["CommunityService", "UduakCharlesDiaries"],
        category: "Uduak Charles Diaries",
        comments: [],
        image: `${rootImagePath}/general/blog/service.jpg`,
        author: "Jennifer Pius",
    },
    {
        date: "2018-09-15",
        title: "REPLACE – Psalms Outreach 2018",
        mainFeature: false,
        featured: false,
        content: `
It is often said that to design the world we want to live in, we must first create it. To experience the new, we have to replace the old.
The new gives one the opportunity to create something better and the advantage it has over the old is that it is current, potent, and is written in bold letters with the ink of impact.
Creating impactful changes is a key tenet of our outreach to communities in need.
We raise building blocks as milestones driven towards rebuilding our societies and we set to achieve this, one outreach at a time.
This was the mindset with which we marched as a team to Efa Ayam/Ikot Obio Iko in Etinan LGA of Akwa Ibom State during our 2018 Psalms outreach. We took it upon ourselves to replace the worn-out signage for their Government Primary School.
With no exact model but the goodness of our hearts and the joy we feel when we reach out to a community in need, we work tirelessly to replace the old with the new through our humanity-centered acts of service. At UCD, we work hand-in-hand as a united team of volunteers to REBUILD REPLACE and RESTORE.
This is the core of what we do!
        `,
        excerpt: "It is often said that to design the world we want to live in",
        slug: "replace-psalms-outreach-2018",
        tags: ["replace", "UduakCharlesDiaries"],
        category: "Psalms Outreach",
        comments: [],
        image: `${rootImagePath}/general/blog/project.jpeg`,
        author: "",
    },
]

export const projects = [
    {
        date: "2016-09-14",
        title: "Psalms 2016",
        slug: "psalms-2016",
        category: "Psalms",
        content: ``,
        location: "",
        image: `${rootImagePath}/2016/psalms/psalms-2016-00003.jpg`,
        imagePath: `${rootImagePath}/2016/psalms/psalms-2016-000`,
        imagesList: ["01.jpg","02.jpg","03.jpg","04.jpg","05.jpg","06.jpg","07.jpg","08.jpg","09.jpg","10.jpg","11.jpg","12.jpg","13.jpg","14.jpg","15.jpg","16.jpg","17.jpg","18.jpg","19.jpg"],
    },
    {
        date: "2017-09-14",
        title: "Psalms 2017",
        slug: "psalms-2017",
        category: "Psalms",
        content: ``,
        location: "",
        image: `${rootImagePath}/2017/psalms/psalms-2017-00008.jpg`,
        imagePath: `${rootImagePath}/2017/psalms/psalms-2017-000`,
        imagesList: ["01.jpg","02.jpg","03.jpg","04.jpg","05.jpg","06.jpg","07.jpg","08.jpg","09.jpg","10.jpg","11.jpg","12.jpg","13.jpg","14.jpg","15.jpg","16.jpg","17.jpg","18.jpg","19.jpg","20.jpg","21.jpg"],
    },
    {
        date: "2018-09-14",
        title: "Psalms 2018",
        slug: "psalms-2018",
        category: "Psalms",
        content: ``,
        location: "",
        image: `${rootImagePath}/2018/psalms/psalms-2018-00004.JPG`,
        imagePath: `${rootImagePath}/2018/psalms/psalms-2018-000`,
        imagesList: ["02.JPG","03.JPG","04.JPG","07.JPG","08.JPG","09.JPG","10.JPG","11.JPG","12.JPG","13.JPG","14.JPG","15.JPG","16.JPG","17.JPG","18.JPG","19.JPG","20.JPG","21.JPG","22.JPG","23.JPG","24.JPG","25.JPG","26.JPG","27.jpg"],
    },
    {
        date: "2019-02-14",
        title: "Girl Child Outreach Uyo 2019",
        slug: "girl-child-outreach-uyo-2019",
        category: "Girl Child Outreach",
        content: ``,
        location: "Uyo",
        image: `${rootImagePath}/2019/gco-uyo/girl-child-uyo-15.jpeg`,
        imagePath: `${rootImagePath}/2019/gco-uyo/girl-child-uyo-`,
        imagesList: ["1.jpeg","2.jpeg","3.jpeg","4.jpeg","5.jpeg","6.jpeg","7.jpeg","8.jpeg","9.jpeg","10.jpeg","11.jpeg","12.jpeg","13.jpeg","14.jpeg","15.jpeg","16.jpeg","17.jpeg","18.jpeg","19.jpeg","20.jpeg","21.jpeg","22.jpeg","23.jpeg","24.jpeg","25.jpeg","26.jpeg","27.jpeg"],
    },
    {
        date: "2019-02-14",
        title: "Girl Child Outreach Yobe 2019",
        slug: "girl-child-outreach-yobe-2019",
        category: "Girl Child Outreach",
        content: ``,
        location: "Yobe",
        image: `${rootImagePath}/2019/gco-yobe/girl-child-yobe-5.jpeg`,
        imagePath: `${rootImagePath}/2019/gco-yobe/girl-child-yobe-`,
        imagesList: ["1.jpeg","2.jpeg","3.jpeg","4.jpeg","5.jpeg","6.jpeg","7.jpeg","8.jpeg","9.jpeg","10.jpeg","11.jpeg","12.jpeg","13.jpeg","14.jpeg"],
    },
    {
        date: "2019-09-14",
        title: "Project Renew (Psalms 2019)",
        slug: "psalms-renew-2019",
        category: "Psalms",
        content: ``,
        location: "Ibianang Ediene, Ikono LGA",
        image: `${rootImagePath}/2019/psalms-renew/project-renew-25.jpeg`,
        imagePath: `${rootImagePath}/2019/psalms-renew/project-renew-`,
        imagesList: ["1.jpeg","2.jpeg","3.jpeg","4.jpeg","5.jpeg","6.jpeg","7.jpeg","8.jpeg","9.jpeg","10.jpeg","11.jpeg","12.jpeg","13.jpeg","14.jpeg","15.jpeg","16.jpeg","17.jpeg","18.jpeg","19.jpeg","20.jpeg","21.jpeg","22.jpeg","23.jpeg","24.jpeg","25.jpeg","26.jpeg"],
    },
    {
        date: "2020-02-14",
        title: "Fight Against COVID 2020",
        slug: "fight-against-covid-2020",
        category: "General Outreach",
        content: ``,
        location: "Uyo",
        image: `${rootImagePath}/2020/fight-covid-19/fight-covid-19-14.jpeg`,
        imagePath: `${rootImagePath}/2020/fight-covid-19/fight-covid-19-`,
        imagesList: ["1.jpeg","2.jpeg","3.jpeg","4.jpeg","5.jpeg","6.jpeg","7.jpeg","8.jpeg","9.jpeg","10.jpeg","11.jpeg","12.jpeg","13.jpeg","14.jpeg","15.jpeg","16.jpeg","17.jpeg"],
    },
    {
        date: "2020-09-14",
        title: "Psalms 2020 Day 1",
        slug: "psalms-2020-day-1",
        category: "Psalms",
        content: ``,
        location: "Uyo",
        image: `${rootImagePath}/2020/psalms-day-1/psalms-day-1-12.jpeg`,
        imagePath: `${rootImagePath}/2020/psalms-day-1/psalms-day-1-`,
        imagesList: ["1.jpeg","2.jpeg","3.jpeg","4.jpeg","5.jpeg","6.jpeg","7.jpeg","8.jpeg","9.jpeg","10.jpeg","11.jpeg","12.jpeg","13.jpeg","14.jpeg","15.jpeg","16.jpeg"],
    },
    {
        date: "2020-09-15",
        title: "Psalms 2020 Day 2",
        slug: "psalms-2020-day-2",
        category: "Psalms",
        content: ``,
        location: "Uyo",
        image: `${rootImagePath}/2020/psalms-day-2/psalms-2020-day-2-18.JPG`,
        imagePath: `${rootImagePath}/2020/psalms-day-2/psalms-2020-day-2-`,
        imagesList: ["1.JPG","2.JPG","3.JPG","4.JPG","5.JPG","6.JPG","7.JPG","8.JPG","9.JPG","10.JPG","11.JPG","12.JPG","13.JPG","14.JPG","15.JPG","16.JPG","17.JPG","18.JPG","19.JPG","20.JPG","21.JPG"],
    },
    {
        date: "2020-09-16",
        title: "Psalms 2020 Day 3",
        slug: "psalms-2020-day-3",
        category: "Psalms",
        content: ``,
        location: "Uyo",
        image: `${rootImagePath}/2020/psalms-day-3/psalms-day-3-6.jpeg`,
        imagePath: `${rootImagePath}/2020/psalms-day-3/psalms-day-3-`,
        imagesList: ["1.jpeg","2.jpeg","3.jpeg","4.jpeg","5.jpeg","6.jpeg","7.jpeg","8.jpeg","9.jpeg"],
    },
    {
        date: "2021-02-14",
        title: "Girl Child Outreach 2021",
        slug: "girl-child-outreach-fstc-2021",
        category: "Girl Child Outreach",
        content: ``,
        location: "Fed. Sci & Tech. College, Uyo",
        image: `${rootImagePath}/2021/gco-uyo-fstc-2021/girl-child-outreach-33.jpeg`,
        imagePath: `${rootImagePath}/2021/gco-uyo-fstc-2021/girl-child-outreach-`,
        imagesList: ["1.jpeg","2.jpeg","3.jpeg","4.jpeg","5.jpeg","6.jpeg","7.jpeg","8.jpeg","9.jpeg","10.jpeg","11.jpeg","12.jpeg","13.jpeg","14.jpeg","15.jpeg","16.jpeg","17.jpeg","18.jpeg","19.jpeg","20.jpeg","21.jpeg","22.jpeg","23.jpeg","24.jpeg","25.jpeg","26.jpeg","27.jpeg","28.jpeg","29.jpeg","30.jpeg","31.jpeg","32.jpeg","33.jpeg"],
    },
    {
        date: "2021-02-14",
        title: "Girl Child Outreach 2022",
        slug: "girl-child-outreach-2022",
        category: "Girl Child Outreach",
        content: ``,
        location: "Calabar",
        image: `${rootImagePath}/2022/gco-calabar/girl-child-calabar-13.jpg`,
        imagePath: `${rootImagePath}/2022/gco-calabar/girl-child-calabar-`,
        imagesList: ["00.jpg","01.jpg","02.jpg","03.jpg","04.jpg","05.jpg","06.jpg","07.jpg","08.jpg","09.jpg","10.jpg","11.jpg","12.jpg","13.jpg","14.jpg","15.jpg","16.jpg","17.jpg","18.jpg"],
    },
    {
        date: "2021-05-24",
        title: "international Day of the Boy Child 2021",
        slug: "international-day-of-the-boy-child-2021",
        category: "International Day of the Boy Child",
        content: ``,
        location: "",
        image: `${rootImagePath}/2021/idbc-2021/idbc-uduak-charles-2021-60.jpeg`,
        imagePath: `${rootImagePath}/2021/idbc-2021/idbc-uduak-charles-2021-`,
        imagesList: [
            "1.jpg","2.jpg","3.jpg","4.jpg","5.jpg","6.jpg","7.jpg","8.jpg","9.jpg","10.jpg","11.jpg","12.jpg","13.jpg","14.jpg","15.jpg","16.jpg","17.jpg","18.jpg","19.jpg","20.jpg",
            "21.jpg","22.jpg","23.jpg","24.jpg","25.jpg","26.jpg","27.jpg","28.jpg","29.jpg","30.jpg","31.jpg","32.jpg","33.jpg","34.jpg","35.jpg","36.jpg","37.jpg","38.jpg","39.jpg","40.jpg",
            "41.jpg","42.jpg","43.jpg","44.jpg","45.jpg","46.jpg","47.jpg","48.jpg","49.jpg","50.jpg","51.jpg","52.jpg","53.jpg","54.jpg","55.jpg","56.jpg","57.jpg","58.jpg","59.jpg","60.jpeg",
        ],
    },
    {
        date: "2021-09-14",
        title: "Psalms 2021",
        slug: "psalms-2021",
        category: "Psalms",
        content: ``,
        location: "",
        image: `${rootImagePath}/2021/psalms/psalms-2021-00021.jpg`,
        imagePath: `${rootImagePath}/2021/psalms/psalms-2021-000`,
        imagesList: [
            "01.jpg","02.jpg","03.jpg","04.jpg","05.jpg","06.jpg","07.jpg","08.jpg","09.jpg","10.jpg","11.jpg","12.jpg","13.jpg","14.jpg","15.jpg","16.jpg","17.jpg","18.jpg","19.jpg","20.jpg",
            "21.jpg","22.jpg","23.jpg","24.jpg","25.jpg","26.jpg","27.jpg","28.jpg","29.jpg","30.jpg","31.jpg","32.jpg","33.jpg","34.jpg","35.jpg","36.jpg","37.jpg","38.jpg","39.jpg","40.jpg",
            "41.jpg","42.jpg","43.jpg","44.jpg",
        ],
    },
    {
        date: "2022-02-14",
        title: "Girl Child Outreach 2022",
        slug: "girl-child-outreach-2022",
        category: "Girl Child Outreach",
        content: ``,
        location: "Adiahaobong Secon. Comm. School",
        image: `${rootImagePath}/2022/gco-adiahaobong-2022/girl-child-uyo-27.jpg`,
        imagePath: `${rootImagePath}/2022/gco-adiahaobong-2022/girl-child-uyo-`,
        imagesList: ["19.jpg","20.jpg","21.jpg","22.jpg","23.jpg","24.jpg","25.jpg","26.jpg","27.jpg","28.jpg","29.jpg","30.jpg","31.jpg","32.jpg","33.jpg","34.jpg","35.jpg","36.jpg","37.jpg","38.jpg"],
    },
    {
        date: "2022-02-14",
        title: "Keke Outreach 2022",
        slug: "keke-outreach-2022",
        category: "General Outreach",
        content: ``,
        location: "Uyo",
        image: `${rootImagePath}/2022/keke-outreach/keke-outreach-1.jpeg`,
        imagePath: `${rootImagePath}/2022/keke-outreach/keke-outreach-`,
        imagesList: ["1.jpeg","2.jpg","3.jpg","4.jpg","5.jpg","6.jpg","7.jpg","8.jpg","9.jpg","10.jpg","11.jpg","12.jpg","13.jpg","14.jpg","15.jpg","16.jpg","17.jpg","18.jpg","19.jpg","20.jpg","21.jpg"],
    },
    {
        date: "2023-02-14",
        title: "Market Women Outreach 2023",
        slug: "market-women-outreach-2023",
        category: "General Outreach",
        content: ``,
        location: "Akpan Andem Market, Uyo",
        image: `${rootImagePath}/2023/market-women-outreach/market-women-outreach00008.jpg`,
        imagePath: `${rootImagePath}/2023/market-women-outreach/market-women-outreach000`,
        imagesList: ["01.jpg","02.jpg","03.jpg","04.jpg","05.jpg","06.jpg","07.jpg","08.jpg","09.jpg","10.jpg","11.jpg","12.jpg","13.jpg","14.jpg","15.jpg","16.jpg","17.jpg","18.jpg","19.jpg","20.jpg","21.jpg","22.jpg","23.jpg","24.jpg","25.jpg","26.jpg","27.jpg","28.jpg","29.jpg","30.jpg","31.jpg","32.jpg","33.jpg"],
    },
]

export const partners = [
    {
        name: "Union Bank",
        image: `${rootImagePath}/general/partners/union-bank.png`,
        imageBW: `${rootImagePath}/general/partners/union-bank-bw.png`,
    },
    {
        name: "Affiong Etuk Foundation for inclusive health",
        image: `${rootImagePath}/general/partners/affih.png`,
        imageBW: `${rootImagePath}/general/partners/affih-bw.png`,
    },
    {
        name: "Padme",
        image: `${rootImagePath}/general/partners/padme.png`,
        imageBW: `${rootImagePath}/general/partners/padme-bw.png`,
    },
    {
        name: "Sijaya",
        image: `${rootImagePath}/general/partners/sijaya.png`,
        imageBW: `${rootImagePath}/general/partners/sijaya-bw.png`,
    },
    {
        name: "Voice of Education Africa",
        image: `${rootImagePath}/general/partners/voice-of-education-africa.png`,
        imageBW: `${rootImagePath}/general/partners/voice-of-education-africa-bw.png`,
    },
    {
        name: "Health Compaass",
        image: `${rootImagePath}/general/partners/health-compass.png`,
        imageBW: `${rootImagePath}/general/partners/health-compass-bw.png`,
    },
]

export const testimonials = [
    {
        name: "Okon Angela Abasiama",
        content: `My name is Okon Angela Abasiama. I am a student of Human Anatomy. University of uyo, uyo... I stay in uyo. I got to know about UC Diaries n psalms o. Social media.I saw a post by a friend on WhatsApp and felt a nudge to get involved.  Psalms 2018 was a dream come true for me.. As I got to practice a profession I love so much. I served in the medical unit.  I'm glad to be part of an organization that provides me a platform to serve humanity. House of psalms is filled with positive and purpose driven people. 
        I look forward to more fun and services.`,
    },
    {
        name: "Ene Chioma Deborah",
        content: `My name is Ene Chioma Deborah. A student of Geography and Environmental Management, University of Port Harcourt. I live in Port Harcourt. Mr Uduak Charles introduced to Psalms.
        I worked with the community service team and fully participated in the painting of the school chalk board. The most important thing which made Psalms 2018 a memorable one was the love and unity that exist between us. It was my first time but it was like I have been with them for ages. Psalms provided me with the opportunity of reaching out to people and putting smiles on their faces. Seeing those smiles on the faces of the people made my journey to Psalms worth it and really appreciate the chairman for creating such a platform.
        No challenge was encountered.
        I suggest skill acquisitions should be attached in the upcoming Psalms 2019 to improve the lives of the people. 
        Thanks.`,
    },
    {
        name: "Okwor Mmesoma",
        content: `My name is Okwor Mmesoma. Am a law student in ebonyi state University. Actually I got the information about Psalms through the chairman on Facebook. He made a post about it last year though but I was not able to meet up but I was able to he part of this year Psalms. Have always had this passion to reach out to people that are actually lacking what I have so Psalms was a answered prayer, I learnt a lot from Psalms .. it was so life changing. Am happy I met the Psalms family, the humility,the hospitality and everything was superb.i did not face any challenges.  I think everything is going on so well,I don't really have future expectations yet because it's a gradual process. I look forward to better days . Love you all`,
    },
    {
        name: "God'slove Reuben",
        content: `My name is God'slove Reuben. I am the CEO of Abascloset, fashion home that is currently in uyo Akwa Ibom state. I heard about UC diaries and Psalms on facebook after seeing a post by the chairman I was moved to find out what psalms was about and thank God I follow the Holy spirit. psalms 2018 was the first time in my life I had the privilege and the platform to put smiles on peoples faces especially the children. It wasn't that easy tho, the late night sewing, abandoning my personal jobs just to meet up the date line but believe me the testimony is massive, I have improve in my field, I get jobs that wow me ever since then, God has been faithful,psalms family is filled with amazing people and I'm blessed to be part of them. God bless house of psalms next year will be great cause we are global.`,
    },
]
import React,{useEffect, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';
import PageBanner from '../layouts/PageBanner';

//images
import grid2 from '../assets/images/blog/blog-grid/pic2.jpg';

import pic4 from '../assets/images/blog/blog-grid/pic4.jpg';
import pic3 from '../assets/images/blog/blog-grid/pic3.jpg';
import pic2 from '../assets/images/blog/blog-grid/pic2.jpg';
import pic5 from '../assets/images/blog/blog-grid/pic5.jpg';
import pic6 from '../assets/images/blog/blog-grid/pic6.jpg';
import pic7 from '../assets/images/blog/blog-grid/pic7.jpg';
import pic8 from '../assets/images/blog/blog-grid/pic8.jpg';
import pic9 from '../assets/images/blog/blog-grid/pic9.jpg';
import pic1 from '../assets/images/blog/blog-grid/pic1.jpg';

import avat1 from '../assets/images/avatar/avatar1.jpg';
import avat2 from '../assets/images/avatar/avatar2.jpg';
import avat3 from '../assets/images/avatar/avatar3.jpg';
import avat4 from '../assets/images/avatar/avatar4.jpg';
import avat5 from '../assets/images/avatar/avatar5.jpg';
import avat6 from '../assets/images/avatar/avatar6.jpg';
import avat7 from '../assets/images/avatar/avatar7.jpg';
import avat8 from '../assets/images/avatar/avatar8.jpg';
import avat9 from '../assets/images/avatar/avatar9.jpg';
import { blogPosts, rootImagePath } from '../data/content';
import { format, parse } from 'date-fns';
import { dataSort } from '../components/Project/ProjectMasonry';

export const cardDetials = [
    {title:"New vaccine for cattle calf loss learned", image:pic4, image2:avat1, tage:"HEALTH", autor:"Hawkins Junior"},
    {title:"4 Things parents learned for they jids in 2020", image:pic3, image2:avat2, tage:"TECHNOLOGY", autor:"Tom wilson"},
    {title:"He Created the Web. Now He’s Out to Remake", image:pic2, image2:avat3, tage:"EDUCATION", autor:"Adam Jordon"},
    {title:"Partnering to create a community", image:pic5, image2:avat4, tage:"DESIGN", autor:"Kaylynn Donin"},
    {title:"Primary School Build for Children", image:pic6, image2:avat5, tage:"FASHION", autor:"Richard Hartisona"},
    {title:"Best & Less published their supplier lists", image:pic7, image2:avat6, tage:"DESIGN", autor:"Cheyenne Curtis"},
    {title:"New vaccine for cattle calf loss learned", image:pic8, image2:avat7, tage:"TECHNOLOGY", autor:"Hawkins Junior"},
    {title:"Smallest of donations can help change a life", image:pic9, image2:avat8, tage:"EDUCATION", autor:"Tom wilson"},
    {title:"It is a long established fact that a reader", image:pic1, image2:avat9, tage:"HEALTH", autor:"Adam Jordon"}
];

const Blog = () => {
    const [blogDropBtn1, setBlogDropBtn1] = useState('All Categories');
    const [ searchParams ] = useSearchParams();
    const [searchText, setSearchText] = useState("");
    const [filtered, setFiltered] = useState([])
    const currentPage = parseInt(searchParams.get('page') || 1);
    const limit = 9
    const totalItems = filtered.length
    const totalPages = Math.ceil(blogPosts.length / limit)

    useEffect(() => {
        let filteredList = blogPosts
            .filter((item) => !item.mainFeature)
            .filter(item => searchText !== "" ? 
            item.title.toLowerCase().includes(searchText.toLowerCase()) : true)
            .sort((a, b) => dataSort(a, b, ['All Categories', 'Newest'].includes(blogDropBtn1)))
        setFiltered(filteredList)
    }, [searchText, blogDropBtn1])

    return (
        <>
            <div className="page-content bg-white">
                <PageBanner maintitle="Home" pagetitle="Blog " background={`${rootImagePath}/general/blog.jpg`}/>
                <section className="content-inner-2">
                    <div className="container">
                        <div className="row">
                            {blogPosts
                            .filter((item) => item.mainFeature)
                            .sort((a, b) => dataSort(a, b, true))
                            .slice(0, 2)
                            .map((item, ind) => (
                            <div className={`col-lg-${ind === 0 ? '4' : '8'} col-md-12 m-b30`} key={ind}>
                                <div className="dz-card style-3 overlay">
                                    <div className="dz-media">
                                        <img src={item.image || grid2} alt="" style={{maxHeight: '520px', width: "auto"}} />
                                    </div>
                                    <div className="dz-info">
                                        <div className="dz-category">
                                            <ul>
                                                <li><Link to={"#"}>{item.category}</Link></li>
                                            </ul>
                                        </div>
                                        <h5 className="dz-title"><Link to={`/${item.slug}`} className="text-white">{item.title}</Link></h5>
                                    </div>
                                </div>
                            </div>
                            ))}
                            {/* <div className="col-lg-5 col-md-12 m-b30">
                                <div className="dz-card style-3 overlay">
                                    <div className="dz-media">
                                        <img src={grid6} alt="" />
                                    </div>
                                    <div className="dz-info">
                                        <div className="dz-category">
                                            <ul>
                                                <li><Link to={"#"}>BUSSINESS STRATEGY</Link></li>
                                            </ul>
                                        </div>
                                        <h5 className="dz-title"><Link to={"/blog-details"} className="text-white">Akcel Academy 2021 Grand Opening at Jakarta</Link></h5>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
                <section className="content-inner-1">
                    <div className="container">
                        <div className="row m-b30">
                            <div className="col-lg-5 search-bx style-2">
                                <div className="input-group  m-b0 m-md-b10">
                                    <input type="text" className="form-control" placeholder="Search Articles" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                    <div className="input-group-prepend">
                                        <button className="btn"><i className="fa-solid fa-magnifying-glass"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-sm-6">
                                        {/* <select className="default-select form-select style-2">
                                            <option>All Categories</option>
                                            <option>Newest</option>
                                            <option>Oldest</option>
                                        </select> */}
                                        <Dropdown className="select-drop style-2">
                                            <Dropdown.Toggle as="div" className="i-false select-drop-btn">
                                                <span>{blogDropBtn1}</span>
                                                <i className="fa-regular fa-angle-down"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={()=>setBlogDropBtn1('All Categories')}>All Categories</Dropdown.Item>
                                                <Dropdown.Item onClick={()=>setBlogDropBtn1('Newest')}>Newest</Dropdown.Item>
                                                <Dropdown.Item onClick={()=>setBlogDropBtn1('Oldest')}>Oldest</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    {/* <div className="col-sm-4">
                                        <select className="default-select form-select style-2">
                                            <option>Filter Date</option>
                                            <option>7/12/2022</option>
                                            <option>15/2/2022</option>
                                        </select>
                                        <Dropdown className="select-drop">
                                            <Dropdown.Toggle as="div" className="i-false select-drop-btn">
                                                <span>{blogDropBtn2}</span>
                                                <i className="fa-regular fa-angle-down"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={()=>setBlogDropBtn2('Filter Date')}>Filter Date</Dropdown.Item>
                                                <Dropdown.Item onClick={()=>setBlogDropBtn2('07/10/2023')}>07/10/2023</Dropdown.Item>
                                                <Dropdown.Item onClick={()=>setBlogDropBtn2('15/11/2023')}>15/11/2023</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div> */}
                                    {/* <div className="col-sm-4">
                                        <select className="default-select form-select style-2">
                                            <option>Latest</option>
                                            <option>Oldest</option>
                                        </select>
                                        <Dropdown className="select-drop">
                                            <Dropdown.Toggle as="div" className="i-false select-drop-btn">
                                                <span>{blogDropBtn3}</span>
                                                <i className="fa-regular fa-angle-down"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={()=>setBlogDropBtn3('Latest')}>Latest</Dropdown.Item>
                                                <Dropdown.Item onClick={()=>setBlogDropBtn3('Oldest')}>Oldest</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {blogPosts
                                .filter((item) => !item.mainFeature)
                                .filter(item => searchText !== "" ? 
                                item.title.toLowerCase().includes(searchText.toLowerCase()) : true)
                                .sort((a, b) => b.date - a.date)
                                .slice((currentPage - 1) * limit, currentPage * limit)
                                .map((item, ind) => (
                                <div className="col-xl-4 col-md-6 m-b30" key={ind}>
                                    <div className="dz-card style-1">
                                        <div className="dz-media">
                                            <Link to={`/${item.slug}`}>
                                                <div style={{
                                                    width: "100%", height: "230px", maxHeight: "230px",
                                                    backgroundImage: `url(${item.image})`,
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center center"
                                                }}></div>
                                                {/* <img src={item.image} alt="" style={{}} /> */}
                                            </Link>
                                            <ul className="dz-badge-list">
                                                <li><Link to={"#"} className="dz-badge">{item.tage}</Link></li>
                                            </ul>
                                            <Link to={`/${item.slug}`} className="btn btn-secondary">Read More</Link>
                                        </div>
                                        <div className="dz-info">
                                            <h5 className="dz-title"><Link to={`/${item.slug}`}>{item.title} </Link></h5>
                                            <p>{item.excerpt}</p>
                                            <div className="author-wrappper">
                                                {/* {item.author &&
                                                <div className="author-media">
                                                    <img src={item.image2} alt="" /> 
                                                </div>
                                                } */}
                                                <div className="author-content">
                                                    {item.author &&
                                                    <div className="author-head">
                                                        <h6 className="author-name">{item.author}</h6>
                                                    </div>
                                                    }
                                                    <ul className="author-meta">
                                                        <li className="date">{format(parse(item.date, "yyyy-MM-dd", new Date()), "d MMMM, yyyy")}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {totalItems > limit &&
                        <div className="row">		
                            <div className="col-12 m-sm-t0 m-t30">		
                                <nav className="pagination-bx gap-4">
                                    <div className="page-item">
                                        <Link to={`/blog?page=${ currentPage > 1 ? currentPage - 1 : currentPage }`} className="page-link prev" disabled={true}>
                                            <i className="fas fa-chevron-left"></i>
                                        </Link>
                                    </div>
                                    {/* <ul className="pagination"> */}
                                        {/* {Array.from(Array(Math.ceil(blogPosts.length / limit))).map((current, index) =>
                                            <li className="page-item"><Link to={`/blog?page=${current}`} className="page-link active">{index + 1}</Link></li>
                                        )} */}
                                        {/* <li className="page-item"><Link to={"#"} className="page-link">2</Link></li>
                                        <li className="page-item"><Link to={"#"} className="page-link">3</Link></li>
                                        <li className="page-item"><Link to={"#"} className="page-link">4</Link></li> */}
                                    {/* </ul> */}
                                    <div className="page-item">
                                        <Link to={`/blog?page=${totalPages > currentPage ? currentPage + 1 : currentPage }`} className="page-link next"><i className="fas fa-chevron-right"></i></Link>
                                    </div>
                                </nav>
                            </div>
                        </div>
                        }
                    </div>
                </section>
            </div>  
        </>
    );
};

export default Blog;
import React from 'react';
import {Link} from 'react-router-dom';
import CountUp from 'react-countup';

import { rootImagePath } from '../data/content';

const CounterBlog = [
    {title:"Projects", number:"15"},
    {title:"Persons Reached", number:"7580"},
    {title:"Items Shared", number:"7000"},
    {title:"Partners", number:"10"},
];

const OurMission = () =>{
    return(
        <>
            <div className="col-lg-5">
                <div className="dz-media">
                    <img src={`${rootImagePath}/general/international_partners.png`} alt="image" />
                </div>
            </div>
            <div className="col-lg-7 m-b50">
                <div className="section-head">
                    <h5 className="sub-title">Our International Friends</h5>
                    <h2>Striving to reach further</h2>
                    <p className="m-t20">
                        We've seen the impact our work has on families and communities. We've seen the hope in the eyes of a child who has access to education, the relief on the face of a parent who can finally afford to feed their family, and the strength of a community that comes together to support each other.
                    </p>
                </div>
                <div className="row">
                    {CounterBlog.map((item, index) => (
                        <div className="col-lg-3 col-sm-4 col-6 m-b30" key={index}>
                            <h2>
                                <span className="counter">
                                    <CountUp
                                        end={item.number} 
                                        separator = ","
                                        duration= "3"
                                    />
                                </span>
                            </h2>
                            <span className="counter-text">{item.title}</span>
                        </div>
                    ))} 
                </div>
                <Link to={"/projects"} className="m-r30 m-sm-r10 btn btn-secondary">See Projects <i className="flaticon-right-arrows ps-3"></i></Link>
            </div>
        </>
    )
}
export default OurMission;
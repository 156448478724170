import React from 'react';
import {Link} from 'react-router-dom';
import DisplayContact from './DisplayContact';
import { SOCIAL } from '../constant/theme';

const FooterCommonData = ({logoImage, iconStyle, className}) => {
    return (
        <>
            <div className="col-xl-4 col-lg-12 col-md-12">
                <div className="widget widget_about">
                    <div className="footer-logo logo-white">
                        <Link to={"/"}><img src={logoImage} alt="" /></Link> 
                    </div>
                    <p><DisplayContact type="about" /></p>
                    <div className="dz-social-icon style-1">
                        <ul>
                            <li><a target="_blank" className="fab fa-facebook-f"  rel="nofollow noreferrer noopener" href={SOCIAL.facebook}></a></li>
                            {" "}<li><a target="_blank" className="fab fa-instagram"  rel="nofollow noreferrer noopener" href={SOCIAL.instagram}></a></li>
                            {" "}<li><a target="_blank" className="fab fa-twitter"  rel="nofollow noreferrer noopener" href={SOCIAL.twitter}></a></li>
                            {" "}<li><a target="_blank" className="fab fa-youtube"  rel="nofollow noreferrer noopener" href={SOCIAL.youtube}></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-3 col-6">
                <div className="widget widget_services">
                    <h5 className="footer-title">Resources</h5>
                    <ul>
                        <li><Link to={"/about-us"}>About Us</Link></li>
                        <li><Link to={"/projects"}>Projects</Link></li>
                        <li><Link to={"/volunteer"}>Volunteer</Link></li>
                        {/* <li><Link to={"/project-story"}>Project Story</Link></li> */}
                        <li><Link to={"/blog"}>Blog</Link></li>
                        <li><Link to={"/contact-us"}>Contact Us</Link></li>
                        {/* <li><Link to={"/terms-and-condition"}>Terms And Condition</Link></li> */}
                    </ul>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="widget widget_getintuch">
                    <h5 className="footer-title">Get in Touch with Us</h5>
                    <ul>
                        <li>
                            {iconStyle   ? 
                                <i className="fas fa-map-marker-alt text-primary"></i>
                                :  
                                <i className="fas fa-map-marker-alt"></i>
                            }
                            <span><DisplayContact type="address" /></span>
                        </li>
                        <li>
                            {iconStyle ? 
                                <i className="fa-solid fa-phone text-primary"></i>
                                :
                                <i className="fa-solid fa-phone"></i>
                            }
                            <span><DisplayContact className={className} type="phone" /></span>
                        </li>
                        <li>
                            {iconStyle ? 
                                <i className="fa fa-envelope text-primary"></i> 
                                :
                                <i className="fa fa-envelope"></i> 
                            }
                            <span><DisplayContact className={className} type="email" /></span>
                        </li>
                    </ul>
                </div>
            </div> 
        </>
    );
};

export default FooterCommonData;
